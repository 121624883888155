// import React, { Component } from 'react';

// import { connect } from 'react-redux';
// import "jspdf-autotable";
// import './styles.css';
// import 'react-date-range/dist/styles.css';
// import 'react-date-range/dist/theme/default.css';
// import { MdOutlineChevronLeft } from "react-icons/md";

// class Transactions extends Component {

//   onClickMenu = (url) => {
//     this.props.history.push(url);
//   };

//   render() {
//     return (
//       <>

//         <section className=' overflow-hidden overflow-y-auto'>
//           <div className='lg:w-7/12 w-11/12 mx-auto py-10'>
//             <div className='bg-[#F6F0FF] px-2 py-4 space-y-6'>
//               <div className="flex items-center justify-between p-6 py-2">
//                 <div className="rounded-full p-1 bg-[#fff] shadow-lg" onClick={() => this.onClickMenu('/app/dashboard')}>
//                   <MdOutlineChevronLeft className='text-[#4E2C7A]' size={30} />
//                 </div>
//                 <div className="">
//                   <h1>About us</h1>
//                 </div>
//                 <div>
//                 </div>
//               </div>
//             </div>
//             <div className='flex flex-col justify-center items-center py-10 space-y-4'>
//               <div className='text-center space-y-6'>
//                 <h1 className='text-[#4E2C7A] text-[24px] font-[700]'>Screening Tool for Specific Learning Disabilities</h1>
//                 <p className='text-center'>First Screen Tool for Specific Learning Disabilities is a checklist of signs and symptoms which has been tested with over 100 children from different schools and different classes. The ages it caters to range from 4 years to 18 years. It is important to keep in mind that the child should have been exposed to at least 1 year of formal instruction (teaching) in a school before attempting to use the tool.</p>
//                 <p className='text-center'>The screening tool should be filled by a parent, a teacher or an adult who ‘knows’ the child's academic and behavioural history. The time taken is about 20-25 minutes per child. The submitted checklist generates a feedback report that highlights the child's performance in different areas (& identifies the areas of difficulty) and suggests the appropriate action plan.</p>
//               </div>
//               <div className='text-center space-y-6'>
//                 <h1 className='text-[#4E2C7A] text-[24px] font-[700]'>First Screen by Orkids!</h1>
//                 <p className='text-center'>This screening app is developed to identify children
//                   <b>‘at risk’ of developmental disorders especially
//                     Specific Learning Disabilities,</b> a disability which
//                   has a high rate of prevalence of 10-12% in every
//                   classroom in our country.
//                 </p>
//                 <p className='text-center'>Orkids’ attempt to empower both parents and
//                   teachers <b>to identify such children and facilitate
//                     early intervention</b> is aimed at reducing the
//                   barriers of distance, time, money and
//                   most importantly language.
//                 </p>
//                 <p className='text-center'><b>Use the app early</b> so as to maximise the overall development of your child.
//                 </p>
//                 <div className='flex justify-center'>
//                   <img src="/img/teacher.png" alt="" />
//                 </div>
//               </div>
//             </div>
//           </div>
//         </section>

//       </>
//     );
//   }
// }

// function mapStateToProps(state) {
//   const { users } = state;
//   const { overview } = users ? users : {};
//   const { setting } = overview ? overview : {};
//   return {
//     users,
//     setting
//   };
// }

// export default connect(mapStateToProps)(Transactions);





import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios'; // Import axios
import "jspdf-autotable";
import './styles.css';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { MdOutlineChevronLeft } from "react-icons/md";

class Transactions extends Component {
  state = {
    data: null,
    loading: true,
    error: null
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    const url = 'https://orkid.jamsara.com';
    const token = `Bearer ${(JSON.parse(window.sessionStorage.getItem("user"))).token}`

    try {
      const response = await axios.post(
        `${url}/api/v1/getAllFirstScreen`,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token
          }
        }
      );

      if (response.data && !response.data.error) {
        this.setState({ data: response.data.data, loading: false });
      } else {
        this.setState({ error: response.data.message, loading: false });
        console.error('Error fetching data:', response.data.message);
      }
    } catch (error) {
      this.setState({ error: error.message, loading: false });
      console.error('API call error:', error);
    }
  };

  onClickMenu = (url) => {
    this.props.history.push(url);
  };

  render() {
    const { data, loading, error } = this.state;

    return (
      <>
        <section className='overflow-hidden overflow-y-auto'>
          <div className='xl:w-7/12 w-11/12 mx-auto py-10'>
            <div className='bg-[#F6F0FF] px-2 py-4 space-y-6'>
              <div className="flex items-center justify-between p-6 py-2">
                <div className="rounded-full p-1 bg-[#fff] shadow-lg" onClick={() => this.onClickMenu('/app/dashboard')}>
                  <MdOutlineChevronLeft className='text-[#4E2C7A]' size={30} />
                </div>
                <div>
                  <h1>About us</h1>
                </div>
                <div></div>
              </div>
            </div>
            <div className='flex flex-col justify-center items-center py-10 space-y-4'>
              <div className='text-center space-y-6'>
                {loading && <p>Loading...</p>}
                {error && <p>Error: {error}</p>}
                {data && (
                  <div className=''>
                    <div className='flex items-center xl:w-7/12 w-11/12 mx-auto  flex-col justify-center text-center space-y-6' dangerouslySetInnerHTML={{ __html: data.link }}>
                    </div>
                  </div>
                )}
              </div>

            </div>
          </div>
        </section>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { users } = state;
  const { overview } = users ? users : {};
  const { setting } = overview ? overview : {};
  return {
    users,
    setting
  };
}

export default connect(mapStateToProps)(Transactions);
