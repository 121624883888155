import React, { Component } from 'react';
import { MdOutlineChevronLeft } from 'react-icons/md'
import { connect } from 'react-redux';
import { IoMdCloseCircle } from "react-icons/io";
import { BiErrorCircle } from "react-icons/bi";
class PreAssessment extends Component {
  constructor(props) {
    super(props);
    this.chartRef = React.createRef();
    this.state = {
      currentQuestion: 0,
      errorMsg: false,
    }
  }

  componentDidMount() {

  }

  static getDerivedStateFromProps(nextProps, prevState) {
    function arrayToObjectFunctionLanguage(arr) {
      let obj = {}
      for (let i = 0; i < arr.length; i++) {
        obj[arr[i].name] = arr[i]["value"][window.localStorage.getItem("language")]
      }
      return obj
    }
    if (nextProps.users.getLanguageSetting2List && Array.isArray(nextProps.users.getLanguageSetting2List)) {
      const objects = arrayToObjectFunctionLanguage(nextProps.users.getLanguageSetting2List)
      window.sessionStorage.setItem('languageSetting2List', JSON.stringify(objects))
    }
  }


  onClickMenu = (url) => {
    this.props.history.push(url);
  };


  handelQuestionSubmit = () => {
    this.setState({
      currentQuestion: this.state.currentQuestion + 1
    })
    if (this.state.currentQuestion > 1) {
      this.props.history.push(`/app/assessment/${this.props.match.params.id}`)
    }
  }

  handelQuestionError = () => {
    this.setState({
      errorMsg: !this.state.errorMsg
    })
  }

  handelClickOnContactUs = () => {
    this.props.history.push("/app/support")
  }



  render() {
    return (
      <section className='overflow-hidden overflow-y-auto'>
        <div className="lg:w-7/12 w-11/12 mx-auto py-10">
          <div className='bg-[#F6F0FF] px-2 py-4 space-y-6'>
            <div className="flex items-center justify-between p-6 py-2">
              <div className="rounded-full p-1 bg-[#fff] shadow-lg" onClick={() => this.props.history.goBack()}>
                <MdOutlineChevronLeft className='text-[#4E2C7A]' size={30} />
              </div>
            </div>
          </div>
          {this.state.currentQuestion === 0 && <div className='py-10 space-y-4'>
            <div className='space-y-6'>
              <p className='text-[32px] font-[700] text-center text-[#4E2C7A]  mb-10'>{window.sessionStorage.getItem("languageSetting2List") ? (JSON.parse(window.sessionStorage.getItem("languageSetting2List")))["Do_you_know_the_child_for_6_months_or_more?"] : "Do you know the child for 6 months or more"}</p>
            </div>

            <div className='flex items-center flex-col justify-center space-y-6'>
              <div className='flex justify-center items-center'>
                <button type="submit" className="text-white bg-[#4E2C7A] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm lg:w-40  px-5 py-2.5 text-center dark:hover:bg-[#4E2C7A] " onClick={this.handelQuestionSubmit}>{window.sessionStorage.getItem("languageSetting2List") ? (JSON.parse(window.sessionStorage.getItem("languageSetting2List")))["YES"] : "Yes"}</button>
              </div>
              <div className='flex justify-center items-center'>
                <button type="submit" className="text-white bg-[#4E2C7A] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm lg:w-40  px-5 py-2.5 text-center dark:hover:bg-[#4E2C7A] " onClick={this.handelQuestionError}>{window.sessionStorage.getItem("languageSetting2List") ? (JSON.parse(window.sessionStorage.getItem("languageSetting2List")))["NO"] : "No"}</button>
              </div>
            </div>
          </div>}
          {this.state.currentQuestion === 1 && <div className='py-10 space-y-4'>
            <div className='space-y-6'>
              {/* <h3 className="md:text-[32px] text-center text-lg font-[800] text-[#000]">Ques. 01</h3> */}
              <p className='text-[32px] font-[700] text-center text-[#4E2C7A]  mb-10'>{(JSON.parse(window.sessionStorage.getItem("languageSetting2List")))["The_Child_has_been_in_a_school_for_more_than_1_year?"] || "Do you know the child for 6 months or more"}</p>
            </div>

            <div className='flex items-center flex-col justify-center space-y-6'>
              <div className='flex justify-center items-center'>
                <button type="submit" className="text-white bg-[#4E2C7A] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm lg:w-40  px-5 py-2.5 text-center dark:hover:bg-[#4E2C7A] " onClick={this.handelQuestionSubmit}>{window.sessionStorage.getItem("languageSetting2List") ? (JSON.parse(window.sessionStorage.getItem("languageSetting2List")))["YES"] : "Yes"}</button>
              </div>
              <div className='flex justify-center items-center'>
                <button type="submit" className="text-white bg-[#4E2C7A] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm lg:w-40  px-5 py-2.5 text-center dark:hover:bg-[#4E2C7A] " onClick={this.handelQuestionError}>{window.sessionStorage.getItem("languageSetting2List") ? (JSON.parse(window.sessionStorage.getItem("languageSetting2List")))["NO"] : "No"}</button>
              </div>
            </div>
          </div>}
          {this.state.currentQuestion === 2 && <div className='py-10 space-y-4'>
            <div className='space-y-6'>
              <p className='text-[32px] font-[700] text-center text-[#4E2C7A] mb-10'>{(JSON.parse(window.sessionStorage.getItem("languageSetting2List")))["Did_you_attempt_the_test_for_the_child_less_than_3_months_ago?"] || "Do you know the child for 6 months or more"}</p>
            </div>

            <div className='flex items-center flex-col justify-center space-y-6'>
              <div className='flex justify-center items-center'>
                <button type="submit" className="text-white bg-[#4E2C7A] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm lg:w-40  px-5 py-2.5 text-center dark:hover:bg-[#4E2C7A] " onClick={this.handelQuestionError}>{window.sessionStorage.getItem("languageSetting2List") ? (JSON.parse(window.sessionStorage.getItem("languageSetting2List")))["YES"] : "Yes"}</button>
              </div>
              <div className='flex justify-center items-center'>
                <button type="submit" className="text-white bg-[#4E2C7A] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm lg:w-40  px-5 py-2.5 text-center dark:hover:bg-[#4E2C7A]" onClick={this.handelQuestionSubmit} >{window.sessionStorage.getItem("languageSetting2List") ? (JSON.parse(window.sessionStorage.getItem("languageSetting2List")))["NO"] : "No"}</button>
              </div>
            </div>
          </div>}
        </div>

        {this.state.errorMsg && <div
          className="fixed z-10 overflow-y-auto top-0 w-full left-0"
          id="modal"
        >
          <div className="flex items-center justify-center  pt-4 px-4 text-center ">
            <div className="fixed inset-0 transition-opacity">
              <div className="absolute inset-0 bg-gray-900 opacity-75"></div>
              <span className="hidden sm:inline-block sm:align-middle sm:h-screen">

              </span>
              <div
                className="inline-block align-center bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all  w-96"
                role="dialog"
                aria-modal="true"
                aria-labelledby="modal-headline"
              >
                <div className="bg-white px-4 pt-5 pb-4  flex justify-center items-center gap-3  flex-col text-center">
                  <BiErrorCircle size={50} color='red' />
                  <div className=' text-5xl text-[#4d207a] font-bold'>oops</div>
                  <div className=' text-lg text-black font-bold'>(we are sorry)</div>
                  <p className=' text-black '>you are ineligible to take the screening test for the child</p>
                  <button
                    type="button"
                    className="py-2 px-4  text-[#4E2C7A] rounded-lg ring-2 ring-[#4E2C7A]  hover:bg-gray-500 mr-2"
                    onClick={this.handelClickOnContactUs}
                  >
                    <i className="fas fa-times" /> {window.sessionStorage.getItem("languageSetting2List") ? (JSON.parse(window.sessionStorage.getItem("languageSetting2List")))["Contact_us"] : "Contact us"}
                  </button>
                </div>

                <IoMdCloseCircle onClick={() => this.setState({ errorMsg: "" })} className=' text-[#FF0000] cursor-pointer absolute top-1 right-1 text-xl' />
              </div>
            </div>
          </div>
        </div>
        }
      </section>
    );
  }
}
const mapStateToProps = (state) => ({
  users: state.users,
});
export default connect(mapStateToProps)(PreAssessment);
