import React, { Component } from "react";
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
// import LoadingOverlay from 'react-loading-overlay';
import Timer from 'otp-timer'
import { AiOutlineMail } from 'react-icons/ai';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

class Signup extends Component {
  constructor(props) {
    super(props);
    // this.props.dispatch(userActions.logout());
    this.state = {
      fieldslogin: {},
      errorslogin: {},
      showPassword: false,
      showConfirmPassword: false,
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.authentication.isForgotSuccess) {
      return {
        ...nextProps,
        fieldslogin: {},
        errorslogin: {},
      }
    } else {
      return {
        ...nextProps
      }
    }
  }

  inputChange = (e) => {

    e.preventDefault();
    let { name, value } = e.target;
    let fieldslogin = this.state.fieldslogin;
    let errorslogin = this.state.errorslogin;
    fieldslogin[name] = value;
    errorslogin[name] = "";
    // console.log(name, value);
    this.setState({ fieldslogin, errorslogin });
  }



  forgotPasswordSubmit = (e) => {
    // e.preventDefault();
    if (this.handleValidationLogin()) {
      let { email } = this.state.fieldslogin;
      this.props.dispatch(userActions.forgotPassword({
        email: email,
      }, this.props));
    }
  }

  // resendOtpSubmit = (e) => {
  //   if (this.handleValidationLogin()) {
  //     let { email } = this.state.fieldslogin;
  //     this.props.dispatch(userActions.forgotPassword({
  //       email: email,
  //     }, this.props));
  //   }
  // }

  otpSubmit = () => {
    // e.preventDefault();
    if (this.handleValidationOTP()) {

      // console.log("handleValidationOTPhandleValidationOTPhandleValidationOTP:::");
      let { token } = this.props.forgotuser;
      let { OTP, password, confirmPassword } = this.state.fieldslogin;
      this.props.dispatch(userActions.forgotUpdatePassword({
        token: token, newPassword: password, confirmnewPassword: confirmPassword,
        otp: OTP
      }, this.props));
    }
  }
  resetForm = (e) => {
    e.preventDefault();
    this.setState({
      fieldslogin: {},
      errorslogin: {},
    })
    this.hideErrorMessage();
  }

  handleValidationOTP = () => {
    let fieldslogin = this.state.fieldslogin;
    let errorslogin = {};
    let formIsValid = true;

    //Name
    if (!fieldslogin["OTP"]) {
      formIsValid = false;
      errorslogin["OTP"] = "Please enter a access code!";
    }
    //password
    // if (!fieldslogin["password"]) {
    //   formIsValid = false;
    //   errorslogin["password"] = "Please enter a password!";
    // }


    if (!fieldslogin["password"] || !fieldslogin["password"].match("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})")) {
      formIsValid = false;
      errorslogin["password"] = "Invalid Password! Password must contain at least 1 uppercase letter, 1 lowercase letter, 1 number, 1 special character, and be at least 8 characters long.";
    }


    //confirmPassword
    if (!fieldslogin["confirmPassword"]) {
      formIsValid = false;
      errorslogin["confirmPassword"] = "Please enter a confirmpassword!";
    }

    this.setState({ errorslogin: errorslogin });
    return formIsValid;
  }

  handleValidationLogin = () => {
    let fieldslogin = this.state.fieldslogin;
    let errorslogin = {};
    let formIsValid = true;

    if (!fieldslogin["email"] || fieldslogin["email"] === '') {
      formIsValid = false;
      errorslogin["email"] = "Please enter a valid email address.";
    }
    //Email
    if (typeof fieldslogin["email"] !== "undefined" && fieldslogin["email"] !== "") {
      let lastAtPos = fieldslogin["email"].lastIndexOf('@');
      let lastDotPos = fieldslogin["email"].lastIndexOf('.');

      if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fieldslogin["email"].indexOf('@@') === -1 && lastDotPos > 2 && (fieldslogin["email"].length - lastDotPos) > 2)) {
        formIsValid = false;
        errorslogin["email"] = "Please enter a valid email address.";
      }
    }

    this.setState({ errorslogin: errorslogin });
    return formIsValid;
  }

  componentDidMount() {
  }
  onClickMenu = (url) => {
    this.props.history.push(url)
  }



  togglePasswordVisibility = () => {
    this.setState(prevState => ({
      showPassword: !prevState.showPassword
    }));
  };

  toggleConfirmPasswordVisibility = () => {
    this.setState(prevState => ({
      showConfirmPassword: !prevState.showConfirmPassword
    }));
  };



  render() {
    let { otpSentForgotPass, loading } = this.props;

    return (
      <>

        {/* <div >
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...' />
        </div> */}

        {/* <div className="bg-[#1D173F] overflow-hidden">

          <div className="flex flex-col flex-1 w-full bg-[#1D173F] py-6 mx-auto overflow-y-auto h-[calc(100vh-6rem)] ">
            <div className="flex flex-col justify-center h-screen px-3 py-10 sm:px-6 lg:px-8">
              <div className="w-full max-w-lg mx-auto">
                <div className="px-6 py-6 border border-gray-800 shadow bg-dark rounded-3xl sm:px-12">
                  <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                    <div className="flex items-center justify-center pt-5 ">
                      <div className="">
                        <img className="w-24 h-24" src="/imge/Images/playstore.png" alt="gcn.png" />
                      </div>
                      <div>
                        <span className=" text-[#977b4b] text-2xl font-extrabold  lg:text-3xl uppercase text-center">GLOBAL CITIZEN
                        </span>
                        <br />
                        <span className=" text-[#977b4b] text-2xl font-extrabold  lg:text-3xl uppercase text-center"> ID WALLET
                        </span>
                      </div>

                    </div>
                  </div>

                  <h2 className="py-3 text-2xl font-medium leading-9 tracking-wide text-center text-white">Forgot Password</h2>

                  <form autoComplete="off">
                    <div className="{otpSent?'disableArea':''}">
                      <label className="text-sm text-white">Email Address</label>
                      <div className="relative mt-1 rounded-md shadow-sm">
                        <input disabled={otpSentForgotPass ? true : false} className={`appearance-none block w-full px-4 pl-4 py-2 h-12 rounded-md text-gray-800 bg-gray-50 focus:outline-none focus:border-gray-200 focus:bg-gray-100 focus:text-gray-800 transition duration-150 ease-in-out text-lg font-normal sm:leading-5 ${!this.state.errorslogin["email"] ? "'border-opacity-20 border-opacity-100 border placeholder-gray-700" : "border-opacity-100 border border-red-500"}`}
                          id="email" name="email" placeholder="" type="email" onChange={this.inputChange} />
                        {this.state.errorslogin["email"] ?
                          <div className="mt-2 font-serif text-red-500 invalid-feedback">
                            {this.state.errorslogin["email"]}
                          </div>
                          : null}
                      </div>
                    </div>

                    <div className="mt-4">
                      {
                        otpSentForgotPass ?
                          <>
                            <div className="mt-4">
                              <label className="text-sm text-white">Access Code</label>
                              <div className="relative mt-1 rounded-md shadow-sm">
                                <input className={`appearance-none block w-full px-4 pl-4 py-2 h-12 rounded-md text-gray-800 bg-gray-50 focus:outline-none focus:border-gray-200 focus:bg-gray-100 focus:text-gray-800 transition duration-150 ease-in-out text-lg font-normal sm:leading-5 ${!this.state.errorslogin["OTP"] ? "'border-opacity-20 border-opacity-100 border placeholder-gray-700" : "border-opacity-100 border border-red-500 bg-dark-400"}`}
                                  id="OTP" name="OTP" placeholder="" type="OTP" onChange={this.inputChange} />
                                {this.state.errorslogin["OTP"] ?
                                  <div className="mt-2 font-serif text-red-500 invalid-feedback">
                                    {this.state.errorslogin["OTP"]}
                                  </div>
                                  : null}
                              </div>
                            </div>

                            <div className="mt-4">
                              <label className="text-sm text-white">New Password</label>
                              <div className="relative mt-1 rounded-md shadow-sm">
                                <input className={`appearance-none block w-full px-4 pl-4 py-2 h-12 rounded-md text-gray-800 bg-gray-50 focus:outline-none focus:border-gray-200 focus:bg-gray-100 focus:text-gray-800 transition duration-150 ease-in-out text-lg font-normal sm:leading-5 ${!this.state.errorslogin["password"] ? "'border-opacity-20 border-opacity-100 border placeholder-gray-700" : "border-opacity-100 border border-red-500 bg-dark-400"}`}
                                  id="password" name="password" placeholder="" type="password" onChange={this.inputChange} />
                                {this.state.errorslogin["password"] ?
                                  <div className="mt-2 font-serif text-red-500 invalid-feedback">
                                    {this.state.errorslogin["password"]}
                                  </div>
                                  : null}
                              </div>
                            </div>

                            <div className="mt-4">
                              <label className="text-sm text-white">Confirm New Password</label>
                              <div className="relative mt-1 rounded-md shadow-sm">
                                <input className={`appearance-none block w-full px-4 pl-4 py-2 h-12 rounded-md text-gray-800 bg-gray-50 focus:outline-none focus:border-gray-200 focus:bg-gray-100 focus:text-gray-800 transition duration-150 ease-in-out text-lg font-normal sm:leading-5 ${!this.state.errorslogin["confirmPassword"] ? "'border-opacity-20 border-opacity-100 border placeholder-gray-700" : "border-opacity-100 border border-red-500 bg-dark-400"}`}
                                  id="confirmPassword" name="confirmPassword" placeholder="" type="password" onChange={this.inputChange} />
                                {this.state.errorslogin["confirmPassword"] ?
                                  <div className="mt-2 font-serif text-red-500 invalid-feedback">
                                    {this.state.errorslogin["confirmPassword"]}
                                  </div>
                                  : null}

                              </div>
                            </div>
                          </>
                          : null
                      }
                    </div>

                    {
                      otpSentForgotPass ?
                        <div className="flex w-5/6 mt-3 md:w-3/6 lg:mt-5 ">
                          <div className="flex items-center py-2 ml-2 lg:ml-0">
                            <Timer textColor={"white"} seconds={1} minutes={2} ButtonText="Resend Access Code" buttonColor="#FFCD2E" background="#1D173F" resend={this.forgotPasswordSubmit} />
                          </div>
                        </div> : null
                    }

                    {
                      otpSentForgotPass ?
                        <div className="mx-auto mt-4">
                          <button className="flex justify-center w-full px-4 py-3 mx-auto text-lg font-semibold text-white transition duration-150 ease-in-out rounded-md all-btn-color focus:outline-none" type="button" onClick={this.otpSubmit}>Verify Access Code</button>
                        </div>
                        :
                        <div className="mx-auto mt-4">
                          <button className="flex justify-center w-full px-4 py-3 mx-auto text-lg font-semibold text-white transition duration-150 ease-in-out rounded-md all-btn-color focus:outline-none" type="button" onClick={this.forgotPasswordSubmit}>Send Access Code</button>
                        </div>
                    }

                  </form>
                  <div className="w-full mx-auto my-3">
                    <div className="flex justify-center pt-3 text-base leading-5 text-center">
                      <p className="ml-0 font-light text-gray-300 transition duration-150 ease-in-out">If you still did not receive access code please reach us out at <span className="text-gray-400 underline">globalcitizenidentitywallet@.com</span></p>
                    </div>
                  </div>
                  <div className="font-light leading-5 text-center">
                    <p className="flex justify-between text-base">
                      <span className="transition duration-150 text-[#FFCD2E] ease-in-out cursor-pointer hover:underline hover:text-shine focus:outline-none focus:underline"></span>
                      <span onClick={() => this.onClickMenu('/login')} className="transition text-[#898CAF] duration-150 ease-in-out cursor-pointer hover:underline focus:outline-none focus:underline">Go Back</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div id="footer" className="fixed bottom-0 w-full">
              <div className="w-full px-4 py-2 all-btn-color">
                <div className="text-[13px] text-white tracking-wide space-y-2 text-center">
                  <strong>© Global Citizen identity Wallet</strong>
                  <div className="flex items-center justify-center w-full">  <img src="/imge/Images/playstore.png" alt="" className="w-10 h-10 object-cover" /></div>
                  <h3> Copyright © 2014-2023</h3>
                </div>
              </div>
            </div>

          </div>
        </div> */}


        <section className="flex items-center w-full">
          <div className="bg-gradient-to-t to-[#5B4DCA] from-[#2D2664] w-full relative lg:block hidden">
            <img className="absolute top-0 left-0" src="/img/Vector.png" alt="" />
            <div className="flex flex-col justify-center items-center h-screen">
              <div>
                <img src="/img/logo.png" alt="" />
              </div>
              <div className="border-t border-[#FFFFFF4D] py-6">
                <h1 className="text-white text-[60px] font-[700] text-center">Welcome to Leytia coin</h1>
                <p className="text-[#FFFFFFA1]/60 text-center text-[26px] font-[400]">Please log in with your details</p>
              </div>
              <div className="flex justify-center items-center absolute bottom-4">
                <a href="https://vuneco.com/" target="_blank" rel="noreferrer" >
                  <img src="/img/product-icon.png" alt="" />
                </a>
              </div>
            </div>
            <img className="absolute bottom-0 right-0" src="/img/Vector-1.png" alt="" />
          </div>
          <div className="bg-[#1C2745] w-full ">
            <div className="flex-col justify-center  flex w-5/6 mx-auto space-y-3 xl:w-3/6 h-screen">
              <div className="text-center">
                <h1 className="text-[45px] font-[700] text-white">Forgot <span className="text-[#4F91F5]">password!</span></h1>
              </div>
              <div className="space-y-2">
                <label className="text-sm font-medium text-white">Email address</label>
                <div className="relative flex items-center">
                  <AiOutlineMail className="absolute right-3 text-gray-400" />
                  <input disabled={this.state.otpSentLogin} className={`w-full border px-4 py-2.5 rounded-md focus:outline-none text-base font-normal  placeholder-white placeholder:text-[#c1bfe9] bg-transparent text-white ${!this.state.errorslogin["email"] ? "'border-opacity-20 border-opacity-100 border placeholder-white font-base " : "border-opacity-100 border-none ring-2 ring-red-300"}`} id="email" name="email" placeholder="example@gmail.com" type="email" onChange={this.inputChange} onKeyPress={(e) => { if (e.key === "Enter") { this.loginSubmit(); } }} />
                </div>
                {this.state.errorslogin["email"] ? <div className="mt-1 font-serif text-red-500 invalid-feedback">{this.state.errorslogin["email"]}</div> : null}
              </div>

              {
                otpSentForgotPass ?
                  <>
                    <div className="space-y-2">
                      <label className="text-sm font-medium text-white">Access code</label>
                      <div className="relative mt-1 rounded-md shadow-sm">
                        <input className={`w-full border px-4 py-2.5 rounded-md focus:outline-none text-base font-normal  placeholder-white placeholder:text-[#c1bfe9] bg-transparent text-white ${!this.state.errorslogin["OTP"] ? "'border-opacity-20 border-opacity-100 border placeholder-white font-base " : "border-opacity-100 border-none ring-2 ring-red-300"}`}
                          id="OTP" name="OTP" placeholder="Enter access code" type="OTP" onChange={this.inputChange} />
                        {this.state.errorslogin["OTP"] ?
                          <div className="mt-2 font-serif text-red-500 invalid-feedback">
                            {this.state.errorslogin["OTP"]}
                          </div>
                          : null}
                      </div>
                    </div>

                    {/* <div className="space-y-2">
                      <label className="text-sm font-medium text-white">New Password</label>
                      <div className="relative mt-1 rounded-md shadow-sm">
                        <input className={`w-full border px-4 py-2.5 rounded-md focus:outline-none text-base font-normal  placeholder-white placeholder:text-[#c1bfe9] bg-transparent text-white ${!this.state.errorslogin["password"] ? "'border-opacity-20 border-opacity-100 border placeholder-white font-base" : "border-opacity-100 border-none ring-2 ring-red-300"}`}
                          id="password" name="password" placeholder="" type="password" onChange={this.inputChange} />
                        {this.state.errorslogin["password"] ?
                          <div className="mt-2 font-serif text-red-500 invalid-feedback">
                            {this.state.errorslogin["password"]}
                          </div>
                          : null}
                      </div>
                    </div> */}

                    <div className="space-y-2">
                      <label className="text-sm font-medium text-white">New password</label>
                      <div className="relative mt-1 rounded-md shadow-sm">
                        <input
                          className={`w-full border px-4 py-2.5 rounded-md focus:outline-none text-base font-normal placeholder-white placeholder:text-[#c1bfe9] bg-transparent text-white ${!this.state.errorslogin["password"] ? 'border-opacity-20 border placeholder-white font-base' : 'border-opacity-100 border-none ring-2 ring-red-300'}`}
                          id="password"
                          name="password"
                          type={this.state.showPassword ? 'text' : 'password'}
                          placeholder="New password"
                          onChange={this.inputChange} onCopy={(e) => { e.preventDefault(); alert("Copying password is not allowed."); }}
                        />
                        <button
                          className="absolute inset-y-0 right-0 pr-3 flex items-center focus:outline-none text-gray-400"
                          onClick={this.togglePasswordVisibility}
                        >
                          {this.state.showPassword ?
                            <FaEye className="text-gray-400 cursor-pointer" />
                            :
                            <FaEyeSlash className="text-gray-400 cursor-pointer" />
                          }
                        </button>
                        {this.state.errorslogin["password"] ?
                          <div className="mt-2 font-serif text-red-500 invalid-feedback">
                            {this.state.errorslogin["password"]}
                          </div>
                          : null}
                      </div>
                    </div>

                    {/* <div className="space-y-2">
                      <label className="text-sm font-medium text-white">Confirm New Password</label>
                      <div className="relative mt-1 rounded-md shadow-sm">
                        <input className={`w-full border px-4 py-2.5 rounded-md focus:outline-none text-base font-normal  placeholder-white placeholder:text-[#c1bfe9] bg-transparent text-white ${!this.state.errorslogin["confirmPassword"] ? "'border-opacity-20 border-opacity-100 border placeholder-white font-base" : "border-opacity-100 border-none ring-2 ring-red-300"}`}
                          id="confirmPassword" name="confirmPassword" placeholder="" type="password" onChange={this.inputChange} />
                        {this.state.errorslogin["confirmPassword"] ?
                          <div className="mt-2 font-serif text-red-500 invalid-feedback">
                            {this.state.errorslogin["confirmPassword"]}
                          </div>
                          : null}

                      </div>
                    </div> */}


                    <div className="space-y-2">
                      <label className="text-sm font-medium text-white">Confirm new password</label>
                      <div className="relative mt-1 rounded-md shadow-sm">
                        <input
                          className={`w-full border px-4 py-2.5 rounded-md focus:outline-none text-base font-normal placeholder-white placeholder:text-[#c1bfe9] bg-transparent text-white ${!this.state.errorslogin["confirmPassword"] ? 'border-opacity-100 border placeholder-white font-base' : 'border-opacity-100 border-none ring-2 ring-red-300'}`}
                          id="confirmPassword"
                          name="confirmPassword"
                          type={this.state.showConfirmPassword ? 'text' : 'password'}
                          placeholder="Confirm password"
                          onChange={this.inputChange} onCopy={(e) => { e.preventDefault(); alert("Copying password is not allowed."); }}
                        />
                        <button
                          className="absolute inset-y-0 right-0 pr-3 flex items-center focus:outline-none text-gray-400"
                          onClick={this.toggleConfirmPasswordVisibility}
                        >
                          {this.state.showConfirmPassword ?
                            <FaEye className="text-gray-400 cursor-pointer" />
                            :
                            <FaEyeSlash className="text-gray-400 cursor-pointer" />
                          }
                        </button>
                        {this.state.errorslogin["confirmPassword"] ?
                          <div className="mt-2 font-serif text-red-500 invalid-feedback">
                            {this.state.errorslogin["confirmPassword"]}
                          </div>
                          : null}
                      </div>
                    </div>
                  </>
                  : null
              }


              {
                otpSentForgotPass ?

                  <div className="">
                    <Timer textColor={"white"} seconds={1} minutes={2} ButtonText="Resend Access Code" buttonColor="#FFCD2E" background="#1D173F" resend={this.forgotPasswordSubmit} />
                  </div>
                  : null
              }

              {
                otpSentForgotPass ?
                  <div className="">
                    <button className="w-full py-3 mx-auto text-white transition duration-150 ease-in-out rounded-[8px] cursor-pointer all-btn-color lg:font-bold focus:outline-none focus:underline" type="button" onClick={this.otpSubmit}>Update Password</button>
                  </div>
                  :
                  <div className="">
                    <button className="w-full py-3 mx-auto text-white transition duration-150 ease-in-out rounded-[8px] cursor-pointer all-btn-color lg:font-bold focus:outline-none focus:underline" type="button" onClick={this.forgotPasswordSubmit}>Send Access Code</button>
                  </div>
              }


              {/* <div className="">
                <button className="w-full py-3 mx-auto text-white transition duration-150 ease-in-out rounded-[8px] cursor-pointer all-btn-color lg:font-bold focus:outline-none focus:underline" type="submit" onClick={this.forgotPasswordSubmit}>Get Reset Link</button>
              </div> */}
              <div className="py-2">
                <p className="text-center text-[18px] font-[400] text-[#A09DC5]">Back To <span onClick={() => this.onClickMenu('/login')} className="text-[#508EF3] cursor-pointer">Sign in ?</span></p>
              </div>
            </div>

          </div>
        </section>

        {/* Whole Structure End*/}
      </>
    )
  }
}
function mapStateToProps(state) {
  const { loggingIn, otpSentForgotPass, forgotuser, loading } = state.authentication;
  const { users, authentication } = state;

  return {
    loggingIn,
    forgotuser,
    otpSentForgotPass,
    users,
    loading,
    authentication
  };
}
export default connect(mapStateToProps)(Signup);