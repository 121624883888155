// ./src/Modal.js
import React, { useState } from 'react';
import { IoMdCloseCircle } from "react-icons/io";
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../../_actions';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { ImCross } from 'react-icons/im';

const Appointment = ({ isOpen, onClose }) => {
  let selector = useSelector(state => state);
  const [formData, setFormData] = useState({
    fullName: '',
    mobile: '',
    email: '',
    date: '',
    time: '',
    period: 'am',
  });
  const dispatch = useDispatch();

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const handlePhoneChange = (value) => {
    setFormData((prevData) => ({
      ...prevData,
      mobile: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const appointmentData = {
      fullName: formData.fullName,
      mobNo: formData.mobile,
      email: formData.email,
      bookingDate: formData.date,
      time: formData.time,
    };
    if (!formData.mobile || formData.mobile == "") {
      alert("Please enter valid mobile number.");
      return;
    }
    console.log("appointmentData", appointmentData);
    dispatch(userActions.saveBookingAppointment(appointmentData, onClose, setFormData));
  };

  const today = new Date().toISOString().split('T')[0];

  return (
    <>
      {/* <div className={isOpen ? "fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-50" : "hidden"}>
        <div className="bg-white p-6 rounded shadow-lg w-11/12 max-w-md md:max-w-lg lg:max-w-xl">
          <div className='flex justify-between mb-2 border-b border-gray-500'>
            <h2 className="text-xl mb-1">Appointment</h2>
            <IoMdCloseCircle className='text-2xl mb-2 text-[#4E2C7A]' onClick={onClose} />
          </div>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="fullName">
                Full Name
              </label>
              <input
                type="text"
                id="fullName"
                value={formData.fullName}
                onChange={handleChange}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="mobile">
                Mobile
              </label>
              <PhoneInput
                country={'in'}
                value={formData.mobile}
                onChange={handlePhoneChange}
                inputStyle={{
                  width: '100%',
                  borderRadius: '4px',
                  padding: '10px',
                  paddingLeft: '48px', // Adjust padding for the flag dropdown
                  outline: 'none',
                  fontSize: '16px',
                  fontFamily: 'inherit',
                  backgroundColor: 'transparent',
                  color: '#000',
                }}
                inputExtraProps={{
                  required: true,
                  autoFocus: true
                }}
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                Email
              </label>
              <input
                type="email"
                id="email"
                value={formData.email}
                onChange={handleChange}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="date">
                Date
              </label>
              <input
                type="date"
                min={today}
                id="date"
                value={formData.date}
                onChange={handleChange}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="time">
                Time
              </label>
              <div className="flex">
                <input
                  type="time"
                  id="time"
                  value={formData.time}
                  onChange={handleChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="HH:MM"
                  required
                />
              </div>
            </div>
            <div className="flex justify-end w-full">
              <button
                type="submit"
                className="bg-[#4E2C7A] text-white px-4 py-2 rounded w-full"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div> */}


      <div className={isOpen ? "fixed w-full top-0 left-0 h-full inset-0 z-50 overflow-hidden mt-0 flex justify-center items-center overflow-y-auto bg-black/40 md:p-0 p-1" : "hidden"} style={{ marginTop: '0rem', }}  >
        <div className="md:w-[32rem] w-11/12  mx-auto rounded-[24px] shadow-lg  overflow-y-auto mt-2">
          <div className="flex items-center justify-between p-6 py-2 bg-[#F6F0FF] ">
            <p className="text-xl font-bold text-[#4E2C7A]">Appointment</p>
            <div className="rounded-full bg-white p-1 shadow-lg" onClick={onClose}>
              <ImCross className='text-[#4E2C7A]' size={12} />
            </div>
          </div>

          <div className='bg-[#fff] px-2 py-4 justify-between items-center rounded-b'>
            <form onSubmit={handleSubmit} class="p-3">
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="fullName">
                  Full Name
                </label>
                <input
                  type="text"
                  id="fullName"
                  value={formData.fullName}
                  onChange={handleChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="mobile">
                  Mobile
                </label>
                <PhoneInput
                  country={'in'}
                  value={formData.mobile}
                  onChange={handlePhoneChange}
                  inputStyle={{
                    width: '100%',
                    borderRadius: '4px',
                    padding: '10px',
                    paddingLeft: '48px', 
                    outline: 'none',
                    fontSize: '16px',
                    fontFamily: 'inherit',
                    backgroundColor: 'transparent',
                    color: '#000',
                  }}
                  inputExtraProps={{
                    required: true,
                    autoFocus: true
                  }}
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="date">
                  Date
                </label>
                <input
                  type="date"
                  min={today}
                  id="date"
                  value={formData.date}
                  onChange={handleChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="time">
                  Time
                </label>
                <div className="flex">
                  <input
                    type="time"
                    id="time"
                    value={formData.time}
                    onChange={handleChange}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    placeholder="HH:MM"
                    required
                  />
                </div>
              </div>
              <div className="flex justify-end w-full">
                <button
                  type="submit"
                  class="text-white bg-[#4E2C7A] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full  px-5 py-2.5 text-center dark:hover:bg-[#4E2C7A] "
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Appointment;
