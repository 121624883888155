import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import uncheck from "./uncheck.png"
import check from "./checked.jpg"
import 'react-confirm-alert/src/react-confirm-alert.css';
import { MdOutlineChevronLeft } from "react-icons/md";
import PhoneInput from 'react-phone-input-2'; // Import PhoneInput
import 'react-phone-input-2/lib/style.css'; // Import PhoneInput styles
import useRazorpay from "react-razorpay";
import { userActions } from '../../_actions';


class Donation extends Component {
  state = {
    data: null,
    loading: true,
    error: null,
    donationType: 'one-time',
    amount: '',
    fullName: '',
    mobile: '',
    email: '',
    address: ''
  };

  componentDidMount() {

  }



  onClickMenu = (url) => {
    this.props.history.push(url);
  };

  handleItemClick = (_id) => {
    this.props.history.push(`/app/service/${_id}`);
  };

  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handlePhoneChange = (value) => {
    this.setState({ mobile: value });
  };

  handleRadioChange = (event) => {
    this.setState({ donationType: event.target.value });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const { donationType, amount, fullName, mobile, email, address } = this.state;

    // You can add an API call to submit the form data
    let obj = {
      amount,
      fullName,
      mobNo: mobile,
      email,
      panCard: address
    }

    this.props.dispatch(userActions.createOrderpaymentVerify(obj)).
      then(response => {
        // console.log("----response---->", response)
        const { orderId, amount, currency, key } = response && response?.createOrderpaymentVerify && response?.createOrderpaymentVerify?.data;
        const options = {
          key: key, // Your Razorpay key
          amount: amount, // Amount in paise
          currency: currency, // Currency
          name: 'OrKids-Foundation',
          description: 'Payment for Order',
          order_id: orderId,
          handler: function (response) {
            // Handle successful payment here
            // console.log('Payment successful:', response);
            // You can dispatch a success action or navigate to a success page
          },
          prefill: {
            name: fullName,
            email: email,
            contact: mobile
          },
          notes: {
            address: address
          },
          theme: {
            color: '#4E2C7A'
          }
        };
        // Initialize and open Razorpay checkout
        if (window.Razorpay) {
          const paymentObject = new window.Razorpay(options);
          paymentObject.open();
        } else {
          console.error("Razorpay script not loaded");
        }

      }).catch((err) => {
        console.log("error", err)
        alert("Failed to create payment. Please try again later.")
      })

  };

  render() {
    const { donationType, amount, fullName, mobile, email, address } = this.state;
    return (
      <>

        <div className="h-screen xl:py-10 py-6 overflow-y-auto overflow-hidden">
          <div className='lg:w-7/12 w-11/12 mx-auto'>
            <div className='bg-[#F6F0FF] px-2 py-4 space-y-6'>
              <div className="flex items-center justify-between p-6 py-2">
                <div className="rounded-full p-1 bg-[#fff] shadow-lg" onClick={() => this.onClickMenu('/app/dashboard')}>
                  <MdOutlineChevronLeft className='text-[#4E2C7A]' size={30} />
                </div>
                <div className="">
                  <h1>Donation</h1>
                </div>
                <div>
                </div>
              </div>
            </div>

            <div className='py-2'>
              <span className='text-sm text-[#4e2c7a] my-1'>
                Your donation shall make a difference in the lives of underprivileged children with Special Education Needs and allow you a tax exemption under section 80G of the IT Act.
                Join us in making a positive impact:
                <span className='font-[800]'> Donate today!</span>
              </span>

              <div>
                <form onSubmit={this.handleSubmit} className='space-y-4'>
                  <div className='flex justify-center items-center mt-2 text-[#4e2c7a] text-xl font-bold'>
                    <label className='flex items-center cursor-pointer'>
                      <img
                        src={donationType === 'one-time' ? check : uncheck}
                        onClick={() => this.setState({ donationType: 'one-time' })}
                        alt="One-Time Donation"
                      />
                      <span onClick={() => this.setState({ donationType: 'one-time' })} className='ml-2'>One-Time</span>
                    </label>
                    <label className='flex items-center cursor-pointer ml-4'>
                      <img
                        src={donationType === 'monthly' ? check : uncheck}
                        onClick={() => this.setState({ donationType: 'monthly' })}
                        alt="Monthly Donation"
                      />
                      <span onClick={() => this.setState({ donationType: 'monthly' })} className='ml-2'>Monthly</span>
                    </label>
                  </div>

                  <div>
                    <label className='block mb-2 text-gray-600'>
                      *Amount:
                      <input
                        placeholder='Amount'
                        type="number"
                        name="amount"
                        value={amount}
                        onChange={this.handleInputChange}
                        className="w-full p-2 border border-gray-200 rounded-lg"
                        required
                      />
                    </label>
                  </div>

                  <div>
                    <label className='block mb-2 text-gray-600'>
                      *Full Name:
                      <input
                        placeholder='Full Name'
                        type="text"
                        name="fullName"
                        value={fullName}
                        onChange={this.handleInputChange}
                        className="w-full p-2 border border-gray-200 rounded-lg"
                        required
                      />
                    </label>
                  </div>

                  <div>
                    <label className='block mb-2 text-gray-600'>
                      *Mobile:
                      <PhoneInput
                        country={'in'}
                        value={mobile}
                        onChange={this.handlePhoneChange}
                        // className="w-full p-2 border border-gray-200 rounded-lg"
                        inputClass="w-full"
                        inputStyle={{
                          width: '100%',
                          borderRadius: '4px',
                          padding: '10px',
                          paddingLeft: '48px', // Adjust padding for the flag dropdown
                          outline: 'none',
                          fontSize: '16px',
                          fontFamily: 'inherit',
                          backgroundColor: 'transparent',
                          color: '#000',
                          padding: "8px",
                          borderColor: "#F2F3F5"

                        }}
                      />
                    </label>
                  </div>

                  <div>
                    <label className='block mb-2 text-gray-600'>
                      *Email:
                      <input
                        placeholder='Email'
                        type="email"
                        name="email"
                        value={email}
                        onChange={this.handleInputChange}
                        className="w-full p-2 border border-gray-200 rounded-lg"
                        required
                      />
                    </label>
                  </div>

                  <div>
                    <label className='block mb-2 text-gray-600'>
                      *Pancard:
                      <input
                        type='text'
                        placeholder='Pancard'
                        name="address"
                        value={address}
                        onChange={this.handleInputChange}
                        className="w-full p-2 border border-gray-200 rounded-lg"
                        rows="3"
                        required
                      />
                    </label>
                  </div>

                  <button
                    type="submit"
                    className="bg-[#4E2C7A] text-white w-full p-2 rounded"
                  >
                    Submit Donation
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { users } = state;
  const { overview } = users ? users : {};
  return {
    users,
    user: overview ? overview.user : null,
  };
}

export default connect(mapStateToProps)(Donation);
