import React, { Component } from 'react';
import { MdOutlineChevronLeft } from 'react-icons/md'
import { alertActions, userActions } from '../../_actions';
import { connect } from 'react-redux';
import { CONST } from '../../_config';
import { authHeader } from '../../_helpers';
import { HiSpeakerWave } from "react-icons/hi2";

class Assessment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      optionId: "",
      currentIndex: 0,
      splashScreen: false,
      splashScreenIndex: null,
      disabled: false,
    };
    this.chartRef = React.createRef();
  }


  componentDidMount() {
    this.props.dispatch(userActions.getAllQuestion())
    this.props.dispatch(userActions.getAllOption())
  }

  static getDerivedStateFromProps(nextProps, prevState) {

    if (typeof window !== 'undefined' && nextProps?.match?.params?.id) {
      const storedIndex = window.localStorage.getItem(`currentQuestion${nextProps.match?.params?.id}`);
      if (storedIndex) {
        return {
          currentIndex: JSON.parse(storedIndex || 0),
        };
      }
    }

    return null;
  }

  onClickMenu = (url) => {
    this.props.history.push(url);
  };

  handelClickOption = (data) => {
    this.setState({ optionId: data })
  }


  handelClickOnNext = () => {
    if (this.state.optionId) {

      const { users } = this.props;
      const { getAllQuestion } = users
      const answer = {
        questionId: getAllQuestion["question"][this.state.currentIndex]._id,
        optionId: this.state.optionId
      }
      if (this.state.currentIndex === 32 || this.state.currentIndex === 65 || this.state.currentIndex === 99) {
        if (this.state.currentIndex === 32) {
          this.setState({ splashScreenIndex: 0 })
        }
        else {
          this.setState({ splashScreenIndex: 1 })
        }
        this.setState({ splashScreen: true })
      }
      const data = window.localStorage.getItem(`currentQuestion${this.props.match?.params?.id}Data`) ? [...JSON.parse(window.localStorage.getItem(`currentQuestion${this.props.match?.params?.id}Data`)), answer] : [answer]
      window.localStorage.setItem(`currentQuestion${this.props.match?.params?.id}Data`, JSON.stringify(data))
      if (this.state.currentIndex < 98) {
        window.localStorage.setItem(`currentQuestion${this.props.match?.params?.id}`, this.state.currentIndex + 1)
        this.setState({ currentIndex: this.state.currentIndex + 1, optionId: "" })
      }
    }
    else {
      this.setState({ splashScreen: false })
    }
  }



  handelOnSubmit = async () => {
    this.handelClickOnNext()
    let data = {
      submitedAns: JSON.parse(window.localStorage.getItem(`currentQuestion${this.props.match?.params?.id}Data`)),
      studentId: this.props.match.params.id,
      screeningMode: window.localStorage.getItem('language')
    }
    let header = new Headers({
      "Content-Type": "application/json",
      Authorization: authHeader().Authorization,
    });
    const requestOptions = {
      method: "POST",
      headers: header,
      body: JSON.stringify(data),
    };

    this.setState({ disabled: true })
    const response = await fetch(CONST.BACKEND_URL + '/submitAnswer', requestOptions)

    const res = await response.json();

    if (res.data) {
      this.setState({ disabled: false })
      window.localStorage.removeItem(`currentQuestion${this.props.match?.params?.id}Data`)
      window.localStorage.removeItem(`currentQuestion${this.props.match?.params?.id}`)
      window.localStorage.removeItem(`language`)
      this.props.history.push(`/app/report/${this.props.match?.params?.id}/${res.data.scoreId}`)
    }
    else {
      this.setState({ disabled: false })
      this.props.dispatch(alertActions.error(res.message))
    }
  }


  handleReadQuestion = (text) => {
    if ('speechSynthesis' in window) {
      const utterance = new SpeechSynthesisUtterance(text);
      utterance.lang = 'hi-IN';

      const voices = window.speechSynthesis.getVoices();
      const hindiVoice = voices.find(voice => voice.lang === 'hi-IN');
      if (hindiVoice) {
        utterance.voice = hindiVoice;
      }

      window.speechSynthesis.speak(utterance);
    } else {
      alert('Your browser does not support speech synthesis.');
    }
  };



  render() {

    const { users } = this.props;
    const { getAllOption, getAllQuestion } = users
    return (
      <section className='overflow-hidden overflow-y-auto'>
        <div className="lg:w-7/12 relative w-11/12 mx-auto py-10">
          <div className='bg-[#F6F0FF] px-2 py-4 space-y-6'>
            <div className="flex items-center justify-between p-6 py-2">
              <div className="rounded-full p-1 bg-[#fff] shadow-lg" onClick={() => this.onClickMenu('/app/dashboard')}>
                <MdOutlineChevronLeft className='text-[#4E2C7A]' size={30} />
              </div>
              <div className="">
                <h1>{window.sessionStorage.getItem("languageSetting2List") ? (JSON.parse(window.sessionStorage.getItem("languageSetting2List")))["Assessment"] : "Assessment"}</h1>
              </div>
              <div>
              </div>
            </div>
          </div>


          <HiSpeakerWave onClick={() => this.handleReadQuestion(
            getAllQuestion["question"][this.state.currentIndex]["question"][window.localStorage.getItem("language")] ||
            getAllQuestion["question"][this.state.currentIndex]["question"]["en"]
          )} className=' absolute right-0 m-10 cursor-pointer text-3xl text-[#4E2C7A]' />






          {!this.state.splashScreen && getAllQuestion && getAllQuestion.question && Array.isArray(getAllQuestion["question"]) && getAllQuestion["question"].length > 0 && <div className='py-10 space-y-4'>

            <div className='space-y-6'>
              <h3


                className="md:text-[32px] text-center text-lg font-[800] text-[#000]">{window.sessionStorage.getItem("languageSetting2List") ? `${(JSON.parse(window.sessionStorage.getItem("languageSetting2List")))["Ques"]} ${this.state.currentIndex + 1}` : `Ques. ${this.state.currentIndex + 1}`}</h3>
              <p
                className='text-[18px] font-[400] text-center'>{getAllQuestion["question"][this.state.currentIndex]["question"][window.localStorage.getItem("language")] || getAllQuestion["question"][0]["question"]["en"]}</p>
            </div>
            <div className='flex justify-center'>
              <img src={getAllQuestion["question"][this.state.currentIndex]["image"][window.localStorage.getItem("language")] ? getAllQuestion["question"][this.state.currentIndex]["image"][window.localStorage.getItem("language")] : getAllQuestion["question"][this.state.currentIndex]["image"]["en"]} alt="" />
            </div>
            <div className='flex items-center justify-center space-x-6'>
              {getAllOption && Array.isArray(getAllOption) && getAllOption.length > 0 ? getAllOption.map((ele, index) => {
                return <div className='flex justify-center items-center' key={index}>
                  <button type="submit" class="text-white bg-[#4E2C7A] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm lg:w-40  px-2.5 py-2.5 text-center dark:hover:bg-[#4E2C7A] " onClick={() => this.handelClickOption(ele._id)}>{ele && ele["name"][window.localStorage.getItem("language")]}</button>
                </div>
              }) : <>
                <div className='flex justify-center items-center'>
                  <button type="submit" class="text-white bg-[#4E2C7A] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm lg:w-40  px-5 py-2.5 text-center dark:hover:bg-[#4E2C7A] ">Yes</button>
                </div>
                <div className='flex justify-center items-center'>
                  <button type="submit" class="text-white bg-[#4E2C7A] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm lg:w-40  px-5 py-2.5 text-center dark:hover:bg-[#4E2C7A] ">No</button>
                </div>
                <div className='flex justify-center items-center'>
                  <button type="submit" class="text-white bg-[#4E2C7A] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm lg:w-40  px-5 py-2.5 text-center dark:hover:bg-[#4E2C7A] ">Sometimes</button>
                </div>
              </>}
            </div>
          </div>}
          {this.state.splashScreen && getAllQuestion && getAllQuestion.splashScreens && <>
            {/* <p className='text-[18px] font-[400] text-center' >{getAllQuestion["splashScreens"][this.state.splashScreenIndex][""]}</p> */}
            <div className='flex justify-center'>
              <img src={getAllQuestion["splashScreens"][this.state.splashScreenIndex]["image"][window.localStorage.getItem("language")] ? getAllQuestion["splashScreens"][this.state.splashScreenIndex]["image"][window.localStorage.getItem("language")] : getAllQuestion["splashScreens"][this.state.splashScreenIndex]["image"]["en"]} alt="" />
            </div>
          </>}

          <div className='bg-[#4E2C7A] px-2 py-4 space-y-6'>
            <div className="flex items-center justify-between p-6 py-2">
              <div>
              </div>
              <div>
              </div>
              <div className="shadow-lg">
                {this.state.currentIndex !== 98 ? <button type="submit" disabled={this.state.optionId === "" && !this.state.splashScreen} className="text-[#4E2C7A] bg-[#fff] focus:ring-4 focus:outline-none font-medium rounded-full text-sm  px-5 py-2.5 text-center" onClick={this.handelClickOnNext} >{window.sessionStorage.getItem("languageSetting2List") ? (JSON.parse(window.sessionStorage.getItem("languageSetting2List")))["Next"] : "Next"}</button> :
                 <button type="submit" disabled={this.state.optionId === "" && !this.state.splashScreen && this.state.disabled} className="text-[#4E2C7A] bg-[#fff] focus:ring-4 focus:outline-none font-medium rounded-full text-sm  px-5 py-2.5 text-center" onClick={this.handelOnSubmit} >
                  {window.sessionStorage.getItem("languageSetting2List") ? (JSON.parse(window.sessionStorage.getItem("languageSetting2List")))["Done"] : "Done"}
                </button>}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
function mapStateToProps(state) {
  const { users } = state;
  return {
    users,
  };
}

export default connect(mapStateToProps)(Assessment);
