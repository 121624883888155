import React, { Component } from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import { MdMail } from 'react-icons/md';
import { AiFillFacebook, AiFillRedditSquare, AiFillTwitterSquare, AiFillMediumSquare, AiFillLinkedin } from 'react-icons/ai';
import { SiTelegram, SiBitcoin } from 'react-icons/si';
import { FaYoutubeSquare, FaInstagramSquare } from 'react-icons/fa';
import { FiMenu } from 'react-icons/fi';

class Contactus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      "name": "",
      "email": "",
      "mobile": "",
      "message": "",
      shown: false,
    }
  }
  handleChange = (event) => {
    event.preventDefault();
    this.setState({ [event.target.name]: event.target.value });
  }
  onClickMenu = (url) => {
    this.props.history.push(url)
  }
  handleSubmit = () => {
    let { name, email, mobile, message } = this.state;
    let reqData = { name, email, mobile, message };
    // console.log("reqData  ", reqData);
    this.props.dispatch(userActions.createNotification(reqData, this.props));
  }
  componentDidMount() {
    // this.props.dispatch(userActions.statics());
  }

  render() {
    return (
      <>
        <div className='h-screen overflow-y-auto'>

          {/* Header section */}
          <div className="w-full bg-[#29092a] h-24 sticky top-0 z-20">
            <div className="flex justify-between items-center max-w-6xl xl:px-0 md:px-10 px-4 h-full text-white mx-auto relative">

              <div>
                <a className='cursor-pointer' onClick={() => this.onClickMenu('/')} href='/' > <img src="/imglogo/GPT_Final Logo_For Dark BG.png" alt="" className="md:w-44 w-36 bg-contain" /></a>
              </div>


              {this.state.shown ? <div className="md:flex   md:relative absolute md:top-0 top-20 right-0 md:w-auto w-full md:bg-transparent bg-[#29092a]  ">
                <ul className="text-white md:flex font-semibold uppercase md:space-x-8 md:space-y-0 space-y-4 md:p-0 p-6 md:text-left text-center">
                  <li className=" cursor-pointer " onClick={() => this.onClickMenu('/')}>Home</li>
                  <li className=" cursor-pointer text-orange-500" onClick={() => this.onClickMenu('/aboutus')}>About</li>
                  <li className=" cursor-pointer" onClick={() => this.onClickMenu('/contactus')}>contact</li>
                  <li className=" cursor-pointer" onClick={() => this.onClickMenu('/login')}>login</li>
                  <li className=" cursor-pointer" onClick={() => this.onClickMenu('/signup')}>Sign Up</li>
                </ul>
              </div> :
                <div className="md:flex hidden   md:relative absolute md:top-0 top-20 right-0 md:w-auto w-full md:bg-transparent bg-[#29092a]  ">
                  <ul className="text-white md:flex font-semibold uppercase md:space-x-8 md:space-y-0 space-y-4 md:p-0 p-6 md:text-left text-center">
                    <li className=" cursor-pointer text-orange-500" onClick={() => this.onClickMenu('/')}>Home</li>
                    <li className=" cursor-pointer" onClick={() => this.onClickMenu('/aboutus')}>About</li>
                    <li className=" cursor-pointer" onClick={() => this.onClickMenu('/contactus')}>contact</li>
                    <li className=" cursor-pointer" onClick={() => this.onClickMenu('/login')}>login</li>
                    <li className=" cursor-pointer" onClick={() => this.onClickMenu('/signup')}>Sign Up</li>
                  </ul>
                </div>}

              <FiMenu onClick={() => this.setState({ shown: !this.state.shown })} className="text-white text-[1.5rem] md:hidden flex" />

            </div>
          </div>

          <div className="w-full bg-[#0d020e] h-80 bg-cover" style={{ backgroundImage: `url("about/AboutusBanner.png")` }}>

          </div>

          <div className="py-10">
            <div className="xl:w-4/6  w-11/12  mx-auto text-center pb-10">
              <h1 className="text-4xl font-bold capitalize text-black">About Us</h1>
              <div className="flex justify-center items-center"><div className="h-1 w-44 bg-[#ce5737]"></div></div>
              <p className='pt-4 text-[14px] text-black'>Bit-Learn aim to create a universe for all the creators through the Revolution in Blockchain Era, in a decentralized way. In the coming year, our team will develop a marketplace that will open doors for collaborations, exclusivity, and profits towards the community. The Bit-Learn team strives to inspire a significant proportion of investors to help make share a bridge connecting the technology to sustainability.</p>
            </div>
            <section className="2xl:w-4/6 xl:w-5/6 w-11/12  mx-auto md:flex items-center md:space-y-0 space-y-10">
              <div className="space-y-6 w-full md:text-left text-center shadow-xl rounded-xl border-4 border-gray-300 py-20 px-4">
                <div className="flex justify-between">
                  <div>
                    <img src='about/costefficient.png' alt='' />
                  </div>
                  <div>
                    <img src='about/animation_200_l637o1au.gif' alt="loading..." className=' h-28' />
                  </div>
                </div>
                <p className="text-base text-black">The team strives to create a fast gaming paradigm for the modern technology.</p>
                <p className="text-base text-black">We provide early stage funding and mentoring to highly scalable green tech companies – while measuring and forecasting the positive environmental impact of a start-up as our central metric of success.</p>
              </div>
              <div className="w-full flex md:justify-end justify-center">
                <img src="about/Section02_Graphics.png" alt="Section02_Graphics ?" title="What is Altcoin ?" className="w-5/6" />
              </div>
            </section>
          </div>


          <div className="bg-cover bg-no-repeat" style={{ backgroundImage: `url("about/section3BG.png")` }}>
            <div className="py-20">
              <section className="2xl:w-4/6 xl:w-5/6 w-11/12  mx-auto">
                {/* text-section */}
                <div className=" space-y-4">

                  <div className="flex justify-center items-center">
                    <div className="space-y-4">
                      <h1 className="text-2xl font-bold capitalize text-white text-center">Bit-Learn is a modular and security-as-a-service platform and offers the following benefits</h1>
                    </div>
                  </div>

                  <div className="grid lg:grid-cols-4 md:grid-cols-2 gap-8 pt-8">
                    {/* card 1 */}
                    <div className="border rounded-md text-center  py-8">
                      <div className='flex justify-center items-center'>
                        <img src="about/eth.png" alt="carrer.png" className="" />
                      </div>
                      <div className="p-4">
                        <h1 className="text-lg font-semibold text-white">ETH COMPATIBILITY</h1>
                      </div>
                    </div>

                    {/* card 1 */}
                    <div className="border rounded-md text-center overflow-hidden py-8">
                      <div className='flex justify-center items-center'>
                        <img src="about/Modularity.png" alt="carrer.png" className="" />
                      </div>
                      <div className="p-4">
                        <h1 className="text-lg font-semibold text-white">MODULARITY</h1>
                      </div>
                    </div>

                    {/* card 1 */}
                    <div className="border rounded-md text-center overflow-hidden py-8">
                      <div className='flex justify-center items-center'>
                        <img src="about/Interoperability.png" alt="carrer.png" className="" />
                      </div>
                      <div className="p-4">
                        <h1 className="text-lg font-semibold text-white">INTEROPERABILITY</h1>
                      </div>
                    </div>

                    <div className="border rounded-md text-center overflow-hidden py-8">
                      <div className='flex justify-center items-center'>
                        <img src="about/Sovereignty.png" alt="carrer.png" className="" />
                      </div>
                      <div className="p-4">
                        <h1 className="text-lg font-semibold text-white">SOVEREIGNTY</h1>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>


          {/* Why us section  */}
          <section className="bg-cover bg- bg-no-repeat" style={{ backgroundImage: `url("about/Section04BG.png")` }}>
            <div className="section02BGmd:flex items-center relative">
              <div className="py-24">
                <div className="2xl:w-4/6 xl:w-5/6 w-11/12 mx-auto">
                  {/* text-section */}
                  <div className="text-center space-y-4">
                    <div className="w-full flex justify-center items-center">
                      {/* <img src="newImg/section02Title.png" alt="" /> */}
                      <h1 className="text-4xl font-medium tracking-wide capitalize text-white">Our Services</h1>
                    </div>
                    <div className="flex justify-center items-center"><div className="h-1 w-52 bg-[#ce5737]"></div></div>
                    <div className="grid lg:grid-cols-4 md:grid-cols-2 xl:gap-24 gap-16 py-10">

                      <div className="space-y-4 text-left cursor-pointer hover:scale-105 scale-100 transition duration-150">
                        <img height={80} width="100%" src="about/Financialmanagement.png" alt="Choose Us" title='Why Choose us' />
                        <h1 className="text-xl font-semibold text-white">Financial management</h1>
                        <p className="text-base text-white">Investors come here for a different experience. To engage with investment advisors who understand them and partner with them in ways that extend far beyond their current financial situation and where the world stands today.</p>
                      </div>

                      <div className="space-y-4 text-left cursor-pointer hover:scale-105 scale-100 transition duration-150">
                        <img height={80} width="100%" src="about/Wealthinvestment.png" alt="Choose Us" title='Why Choose us' />
                        <h1 className="text-xl font-semibold text-white">Wealth investment</h1>
                        <p className="text-base text-white">Because we don’t sit across the table from you. We sit side-by-side. To understand things as you see them, to observe and evaluate together. To advise, guide, counsel with your larger life and future needs and goals in mind. And to do so realistically. Candidly. Consistently.</p>
                      </div>

                      <div className="space-y-4 text-left cursor-pointer hover:scale-105 scale-100 transition duration-150">
                        <img height={80} width="100%" src="about/Professionalapproach.png" alt="Choose Us" title='Why Choose us' />
                        <h1 className="text-xl font-semibold text-white">Professional approach</h1>
                        <p className="text-base text-white">Because being steady performers matters! A team of seasoned investment professionals. Who deliver sound strategies and fresh opportunities through all market cycles. Professionals with cool heads and steady hands — in exciting and in anxious times</p>
                      </div>

                      <div className="space-y-4 text-left cursor-pointer hover:scale-105 scale-100 transition duration-150">
                        <img height={80} width="100%" src="about/Compatibleinstitute.png" alt="Choose Us" title='Why Choose us' />
                        <h1 className="text-xl font-semibold text-white">Compatible institute</h1>
                        <p className="text-base text-white">Because our clients continue here for decades. Individuals, families, family businesses, next generations and institutions. Ten, twenty, thirty years later, original relationships endure, grow and flourish with us. A constant vote of confidence in our ability, compatibility, philosophy and process.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>






          {/* footer section  */}
          <section className="w-full   bg-cover" style={{ backgroundImage: `url("newImg/FOOTER.png")` }}>
            <footer className="pt-20" >
              <div className=" 2xl:w-8/12 w-5/6  mx-auto lg:flex  py-10 lg:space-x-20 lg:space-y-0 space-y-10 justify-between ">

                <div className="lg:w-6/12 w-full">
                  <div className="space-y-4">
                    <img src="/imglogo/GPT_Final Logo_For Dark BG.png" alt="" className="w-48 bg-contain flex justify-center" />
                    <h2 className="text-left text-white  text-sm ">Bit-Learn provides clients with the industry’s broadest range of solutions to meet their investment objectives, continued investment in technology and our ambition is to give more people access to financial markets.</h2>
                  </div>
                </div>


                <div className="lg:w-3/12 w-full">
                  <h1 className="uppercase font-semibold text-white text-2xl py-4 " >Social media</h1>
                  <div className="flex flex-wrap gap-4   text-white">

                    <a className="cursor-pointer" href='https://www.facebook.com/Efin-Cap-101877035960050' target="blank" > <AiFillFacebook className="text-[1.5rem]" /></a>

                    <a className="cursor-pointer" href='https://www.reddit.com/user/EFinCap' target="blank" > <AiFillRedditSquare className="text-[1.5rem]" /></a>
                    <a className="cursor-pointer" href='https://twitter.com/CaptialEfin' target="blank" ><AiFillTwitterSquare className="text-[1.5rem]" /></a>

                    <a className="cursor-pointer" href='https://t.me/EFinCap' target="blank" > <SiTelegram className="text-[1.5rem]" /></a>

                    <a className="cursor-pointer" href='https://medium.com/@Bit-Learn' target="blank" ><AiFillMediumSquare className="text-[1.5rem]" /></a>
                    <a className="cursor-pointer" href='https://www.instagram.com/GPT_tech/' target="blank" ><FaInstagramSquare className="text-[1.5rem]" /></a>

                    <a className="cursor-pointer" href='https://bitcointalk.org/index.php?action=profile;u=3493952;sa=showPosts' target="blank" ><SiBitcoin className="text-[1.5rem]" /></a>
                    <a className="cursor-pointer" href='https://www.linkedin.com/in/efin-captial-384b47246/?original_referer=http%3A%2F%2F127.0.0.1%3A5555%2F' target="blank" ><AiFillLinkedin className="text-[1.5rem]" /></a>
                    <a className="cursor-pointer" href='https://www.youtube.com/channel/UCum_a5LW2IWHfJ8WMJ0fS5A' target="blank" ><FaYoutubeSquare className="text-[1.5rem]" /></a>


                  </div>
                </div>

                <div className="lg:w-3/12 text-white w-full ">
                  <h1 className="uppercase text-2xl text-white py-4" >contact us</h1>

                  <div className="space-y-2" >
                    <div className="flex items-center space-x-2" > <MdMail className="text-[1.5rem]" /> <p className="text-white text-base" >info@Bit-Learn.io</p> </div>
                    <div className="flex items-center space-x-2" ><MdMail className="text-[1.5rem]" /> <p className="text-white text-base" >support@Bit-Learn.io</p></div>
                  </div>

                </div>

              </div>

              <div className="text-white text-sm py-2 text-center bg-white/10">© 2022 All Rights Reserved</div>

            </footer>
          </section>
        </div>

      </>
    );
  }
}
function mapStateToProps(state) {
  const { users, authentication } = state;
  return {
    users,
    authentication
  };
}
export default connect(mapStateToProps)(Contactus);