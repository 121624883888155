import React, { Component } from 'react';
import { MdOutlineChevronLeft } from 'react-icons/md';
import { connect } from 'react-redux';
// import LoadingOverlay from 'react-loading-overlay';
import { userActions } from '../../_actions';
import classNames from 'classnames';

class Send extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedLanguage: '',
    };
  }

  componentDidMount() {
    const data = {
      keyWord: '',
      pageNo: 1,
      sortBy: 'createdAt',
      sortOrder: 'asc',
      fromDate: '',
      toDate: '',
      size: 20,
    };
    this.props.dispatch(userActions.getLanguageList(data));
  }

  onClickMenu = (url) => {
    this.props.history.push(url);
  };

  selectLanguage = (language) => {
    window.localStorage.setItem('language', language);
    this.setState({ selectedLanguage: language });

  };

  continueClicked = () => {
    console.log('Continue button clicked for language:', this.state.selectedLanguage);
  };

  handleOpenNetwork = () => {
    this.props.dispatch(userActions.getLanguageSetting2List({ languageShortName: this.state.selectedLanguage }))
    this.props.history.push(`/app/Introduction/${this.props.match.params.id}`);
  };

  render() {
    const localdata = window.localStorage.getItem('language');
    const { users } = this.props;
    const { loading } = users;
    const { selectedLanguage } = this.state;

    return (
      <>
        {/* <LoadingOverlay active={loading} className="temp001" spinner text="Please wait..." /> */}

        <div className="mb-4 xl:py-10 py-6 overflow-y-auto flex justify-center">
          <div className="lg:w-7/12 w-11/12 mx-auto">
            <div className="bg-[#F6F0FF] px-2 py-4 space-y-6">
              <div className="flex items-center justify-between p-6 py-2">
                <div className="rounded-full p-1 bg-[#fff] shadow-lg" onClick={this.props.history.goBack}>
                  <MdOutlineChevronLeft className="text-[#4E2C7A]" size={30} />
                </div>
                <div>
                  <h1>Select Language</h1>
                </div>
                <div>

                </div>
              </div>
            </div>
            <div className="grid lg:grid-cols-3 grid-cols-2 gap-10 py-10">
              {Array.isArray(users?.languageList?.languageList?.data?.list) &&
                users.languageList.languageList.data.list.length > 0 &&
                users.languageList.languageList.data.list.map((ele) => (
                  <div
                    key={ele._id}
                    className={classNames(
                      'p-6 flex justify-center items-center rounded-[10px] cursor-pointer',
                      {
                        'bg-[#4E2C7A] text-white': localdata === ele.shortName,
                        'bg-[#EDEDED]': localdata !== ele.shortName,
                      }
                    )}
                    onClick={() => this.selectLanguage(ele.shortName)}
                  >
                    {ele.scriptName}
                  </div>
                ))}
            </div>

            <button
              type="button"
              className={classNames(
                'text-white bg-[#4E2C7A] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full px-5 py-2.5 text-center',
                {
                  'disabled:opacity-50': !selectedLanguage,
                }
              )}
              disabled={!selectedLanguage}
              onClick={this.handleOpenNetwork}
            >
              Continue
            </button>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.users,
});

export default connect(mapStateToProps)(Send);
