import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import { FaEdit } from "react-icons/fa";
import { ImCross } from "react-icons/im";

export class EditStudent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      pageNo: 1,
      size: 10,
      filedData: {
        "fullName": "",
        "mobNo": "",
        "email": "",
        "gender": "",
        "grade": "",
        "dob": "",
        "medium": "",
        "profileImg": "",
        "motherTongue": "",
        "parentEducation": "",
        "parentIncome": "",
        "schoolName": "",
        "board": ""
      },
      imageUpload: false,
      errorData: {}
    };
    this.fileInputRef = createRef();
  }

  componentDidMount() {
    const data = {
      "keyWord": "",
      "pageNo": 1,
      "sortBy": "createdAt",
      "sortOrder": "asc",
      "fromDate": "",
      "toDate": "",
      "size": 20
    }
    this.props.dispatch(userActions.getLanguageList(data))
    this.props.dispatch(userActions.getAllBoard())
  }

  handelSubmitStudent = (e) => {
    if(this.handleValid()){
      e.preventDefault();
      const dataObj = { ...this.state.filedData };
      if (this.state.imageUpload) {
        dataObj["profileImg"] = this.props.users.filesDetails.imageURL;
      }
      this.props.dispatch(userActions.createNewStudent(dataObj, this.props));
    }
 
  }

   handleValid =()=>{
    let error={};
    let valid = true;   
    if (!this.state.filedData.fullName) {
      error.fullName = "Full Name is required";
      valid = false;
    }
    if (!this.state.filedData.mobNo) {
      error.mobNo = "Mobile Number is required";
      valid = false;
    }
    if (!this.state.filedData.email) {
      error.email = "Email is required";
      valid = false;
    }
    if (!this.state.filedData.dob) {
      error.dob = "Date of Birth is required";
      valid = false;
    }
    if (!this.state.filedData.gender) {
      error.gender = "Gender is required";
      valid = false;
    }
    if (!this.state.filedData.grade) {
      error.grade = "Grade is required";
      valid = false;
    }
    if (!this.state.filedData.medium) {
      error.medium = "Medium is required";
      valid = false;
    }
    // if (!this.state.filedData.parentEducation) {
    //   error.parentEducation = "Parent Education is required";
    //   valid = false;
    // }
    // if (!this.state.filedData.parentIncome) {
    //   error.parentIncome = "Parent Income is required";
    //   valid = false;
    // }
    if (!this.state.filedData.schoolName) {
      error.schoolName = "School Name is required";
      valid = false;
    }
    if (!this.state.filedData.board) {
      error.board = "Board is required";
      valid = false;
    }
    console.log(error)
    this.setState({ errorData: error });

    return valid
  }

  handelOnChnage = (e) => {
    const { name, value } = e.target;
    if(name=="mobNo" && value.length>10){
      return;
    }

    this.setState(prevState => ({
      filedData: {
        ...prevState.filedData,
        [name]: value
      },
      errorData: {
       ...prevState.errorData,
        [name]: ""
      }
    }));
  }

  handelOnchangeImage = (e) => {
    const formData = new FormData();
    formData.append("image", e.target.files[0]);
    this.setState({ imageUpload: true });
    this.props.dispatch(userActions.uploadImage(formData));
  }

  onClickMenu = (url) => {
    this.props.history.push(url);
  }

  handleEditClick = () => {
    this.fileInputRef.current.click();
  }

  render() {
    let { users } = this.props;
    let { filesDetails } = users ? users : {};
    let { imageURL } = filesDetails ? filesDetails : {};
    const today = new Date().toISOString().split('T')[0];
    return (
      <section className='overflow-hidden overflow-y-auto  lg:pb-0 pb-20'>
        <div className='lg:w-8/12 w-11/12 mx-auto py-10 overflow-hidden overflow-y-auto'>
          <div className='bg-[#fff] px-2 py-4 space-y-6'>
            <div className="flex items-center justify-between p-6 py-2 bg-[#F6F0FF]">
              <p className="text-xl font-bold text-[#4E2C7A]">Add Student</p>
              <div className="rounded-full bg-white p-1 shadow-lg" onClick={() => this.onClickMenu('/app/dashboard')}>
                <ImCross className='text-[#4E2C7A]' size={12} />
              </div>
            </div>
            <div className='flex justify-center relative items-start'>
              {imageURL ? <img src={imageURL} className='rounded-full w-40 h-40' alt='' /> : <img src="/img/Image.png" alt="imggg" className='rounded-full w-40 h-40' />}
            
              <span className=' p-1 absolute -bottom-2 border border-gray-500 bg-white h-8 w-8 rounded-full  flex justify-center items-center'>
                <FaEdit size={28} className=' text-[#4E2C7A]' onClick={this.handleEditClick} />

              </span>

              <input type='file' ref={this.fileInputRef} style={{ display: 'none' }} onChange={this.handelOnchangeImage} />
            </div>
            <div className='space-y-4'>
              <form className="grid lg:grid-cols-2 gap-6">
                <div className="space-y-2">
                  <label for="text" className="block text-sm font-medium text-gray-900 ">*Full Name</label>
                  <input type="text" id="text" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-[#F6F0FF] dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500 shadow-lg" name='fullName' onChange={this.handelOnChnage} value={this.state.filedData.fullName} required />
                {this.state.errorData&&this.state.errorData["fullName"]&&<span className=' text-xs text-red-700'>{this.state.errorData["fullName"]}</span>}
                </div>


                <div className="space-y-2">
                  <label for="date" className="block text-sm font-medium text-gray-900 ">*Date of Birth</label>
                  <input type="date"  max={today} id="date" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-[#F6F0FF] dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500 shadow-lg" name='dob' onChange={this.handelOnChnage} value={this.state.filedData.dob} required />
                  {this.state.errorData&&this.state.errorData["dob"]&&<span className=' text-xs text-red-700'>{this.state.errorData["dob"]}</span>}
                </div>
                <div className="space-y-2">
                  <label for="date" className="block text-sm font-medium text-gray-900 ">*Gender</label>
                  <div className="relative border rounded-lg border-gray-300 text-gray-800  shadow-lg">
                    <select className="appearance-none text-sm  w-full p-2.5 bg-[#F6F0FF] rounded-lg" name="gender" id="frm-whatever" onChange={this.handelOnChnage} value={this.state.filedData.gender}>
                      <option value="">Please choose&hellip;</option>
                      <option value="MALE">Male</option>
                      <option value="FEMALE">Female</option>
                      <option value="OTHER">Others</option>
                    </select>
                    <div className="pointer-events-none absolute right-0 top-0 bottom-0 flex items-center px-2 text-gray-700 border-l">
                      <svg className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                      </svg>
                    </div>
                  </div>
                    {this.state.errorData&&this.state.errorData["gender"]&&<span className=' text-xs text-red-700'>{this.state.errorData["gender"]}</span>}
                </div>
                <div className="space-y-2">
                  <label for="number" className="block text-sm font-medium text-gray-900 ">*Mobile</label>
                  <input type="number" id="number" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-[#F6F0FF] dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500 shadow-lg " name='mobNo' value={this.state.filedData.mobNo} required onChange={this.handelOnChnage} />
                  {this.state.errorData&&this.state.errorData["mobNo"]&&<span className=' text-xs text-red-700'>{this.state.errorData["mobNo"]}</span>}
              
                </div>
                <div className="space-y-2">
                  <label for="email" className="block text-sm font-medium text-gray-900 ">*Email</label>
                  <input type="email" id="email" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-[#F6F0FF] dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500 shadow-lg"  name='email' value={this.state.filedData.email} onChange={this.handelOnChnage} required />
                  {this.state.errorData&&this.state.errorData["email"]&&<span className=' text-xs text-red-700'>{this.state.errorData["email"]}</span>}
              
                </div>
                <div className="space-y-2">
                  <label for="date" className="block text-sm font-medium text-gray-900 ">*Mother Tongue</label>
                  <div className="relative border rounded-lg border-gray-300 text-gray-800  shadow-lg">
                    <select className="appearance-none text-sm  w-full p-2.5 bg-[#F6F0FF] rounded-lg" name="motherTongue" id="frm-whatever" value={this.state.filedData.motherTongue} onChange={this.handelOnChnage}>
                      <option value="">Select Mother Tongue&hellip;</option>
                      {Array.isArray(users?.languageList?.languageList?.data?.list) && (users?.languageList?.languageList?.data?.list.length > 0) && users?.languageList?.languageList?.data?.list.map((ele) => {
                        return <option value={ele.name}>{ele.name}</option>
                      })}
                    </select>
                    <div className="pointer-events-none absolute right-0 top-0 bottom-0 flex items-center px-2 text-gray-700 border-l">
                      <svg className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                      </svg>
                    </div>
                  </div>
                  {this.state.errorData&&this.state.errorData["motherTongue"]&&<span className=' text-xs text-red-700'>{this.state.errorData["motherTongue"]}</span>}
              
                </div>
                <div className="space-y-2">
                  <label for="date" className="block text-sm font-medium text-gray-900 ">*Grade</label>
                  <div className="relative border rounded-lg border-gray-300 text-gray-800  shadow-lg">
                    <select className="appearance-none text-sm  w-full p-2.5 bg-[#F6F0FF] rounded-lg" id="frm-whatever" onChange={this.handelOnChnage} name='grade' value={this.state.filedData.grade}>
                      <option value="">Select Grade&hellip;</option>
                      <option value="1">Class 1</option>
                      <option value="2">Class 2</option>
                      <option value="3">Class 3</option>
                      <option value="4">Class 4</option>
                      <option value="5">Class 5</option>
                      <option value="6">Class 6</option>
                      <option value="7">Class 7</option>
                      <option value="8">Class 8</option>
                      <option value="9">Class 9</option>
                      <option value="10">Class 10</option>
                      <option value="11">Class 11</option>
                      <option value="12">Class 12</option>

                    </select>
                    <div className="pointer-events-none absolute right-0 top-0 bottom-0 flex items-center px-2 text-gray-700 border-l">
                      <svg className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                      </svg>
                    </div>
                  </div>
                  {this.state.errorData&&this.state.errorData["grade"]&&<span className=' text-xs text-red-700'>{this.state.errorData["grade"]}</span>}
                </div>
                <div className="space-y-2">
                  <label for="text" className="block text-sm font-medium text-gray-900 ">*School Name</label>
                  <input type="text" id="text" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-[#F6F0FF] dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500 shadow-lg"  name='schoolName' value={this.state.filedData.schoolName} onChange={this.handelOnChnage} required />
                  {this.state.errorData&&this.state.errorData["schoolName"]&&<span className=' text-xs text-red-700'>{this.state.errorData["schoolName"]}</span>}
                </div>
                <div className="space-y-2">
                  <label for="date" className="block text-sm font-medium text-gray-900 ">*Education Board</label>
                  <div className="relative border rounded-lg border-gray-300 text-gray-800  shadow-lg">
                    <select className="appearance-none text-sm  w-full p-2.5 bg-[#F6F0FF] rounded-lg" name="board" id="frm-whatever" value={this.state.filedData.board} onChange={this.handelOnChnage}  >
                      <option value="">Select Board&hellip;</option>
                      {Array.isArray(users?.bordList?.data) && users?.bordList?.data.length > 0 && users?.bordList.data.map((ele) => {
                        return <option value={ele.name}>{ele.name}</option>
                      })}
                    </select>
                    <div className="pointer-events-none absolute right-0 top-0 bottom-0 flex items-center px-2 text-gray-700 border-l">
                      <svg className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                      </svg>
                    </div>
                  </div>
                  {this.state.errorData&&this.state.errorData["board"]&&<span className=' text-xs text-red-700'>{this.state.errorData["board"]}</span>}
                </div>
                <div className="space-y-2">
                  <label for="date" className="block text-sm font-medium text-gray-900 whitespace-nowrap ">*What is the medium of the instruction in the child's school</label>
                  <div className="relative border rounded-lg border-gray-300 text-gray-800  shadow-lg">
                    <select className="appearance-none text-sm  w-full p-2.5 bg-[#F6F0FF] rounded-lg" name="medium" id="frm-whatever" value={this.state.filedData.medium} onChange={this.handelOnChnage} >
                      <option value="">Select Medium&hellip;</option>
                      <option value="English">English</option>
                      <option value="Hindi">Hindi</option>
                      <option value="Regional">Regional</option>
                    </select>
                    <div className="pointer-events-none absolute right-0 top-0 bottom-0 flex items-center px-2 text-gray-700 border-l">
                      <svg className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                      </svg>
                    </div>
                  </div>
                  {this.state.errorData&&this.state.errorData["medium"]&&<span className=' text-xs text-red-700'>{this.state.errorData["medium"]}</span>}
                </div>
                {/* <div className="space-y-2">
                  <label for="date" className="block text-sm font-medium text-gray-900 ">*Parents Education</label>
                  <div className="relative border rounded-lg border-gray-300 text-gray-800  shadow-lg">
                    <select className="appearance-none text-sm  w-full p-2.5 bg-[#F6F0FF] rounded-lg" name="parentEducation" value={this.state.filedData.parentEducation} onChange={this.handelOnChnage} id="frm-whatever">
                      <option value="">Select Education&hellip;</option>
                      <option value="Secondary">Secondary</option>
                      <option value="HS">Higher Secondary</option>
                      <option value="UG">Graduation</option>
                      <option value="PG">Post Graduation</option>
                    </select>
                    <div className="pointer-events-none absolute right-0 top-0 bottom-0 flex items-center px-2 text-gray-700 border-l">
                      <svg className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                      </svg>
                    </div>
                  </div>
                </div>
                <div className="space-y-2">
                  <label for="date" className="block text-sm font-medium text-gray-900 ">*Parent's Annual Income</label>
                  <div className="relative border rounded-lg border-gray-300 text-gray-800  shadow-lg">
                    <select className="appearance-none text-sm  w-full p-2.5 bg-[#F6F0FF] rounded-lg" name="parentIncome" onChange={this.handelOnChnage} value={this.state.filedData.parentIncome} id="frm-whatever">
                      <option value="">Select&hellip;</option>
                      <option value="< 100000-200000">Below 1 lakhs to 2 lakhs</option>
                      <option value="200000-500000">2 lakhs to 5 lakhs</option>
                      <option value="600000-900000">6 lakhs to 9 lakhs</option>
                      <option value="1000000-1500000">10 lakhs to 15 lakhs</option>
                    </select>
                    <div className="pointer-events-none absolute right-0 top-0 bottom-0 flex items-center px-2 text-gray-700 border-l">
                      <svg className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                      </svg>
                    </div>
                  </div>
                </div> */}
              </form>
              <button type="submit" onClick={this.handelSubmitStudent} className="text-white mt-2 bg-[#4E2C7A] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full  px-5 py-2.5 text-center dark:hover:bg-[#4E2C7A] ">Add Student</button>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

function mapStateToProps(state) {
  const { users } = state;
  return {
    users
  };
}

export default connect(mapStateToProps)(EditStudent);
