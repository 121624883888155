import { userConstants } from '../_constants';
import { userService } from '../_services';
import { alertActions, dashboardActions } from '.';
// import { history } from '../_helpers';
export const userActions = {

    login,
    loginValidateOtp,
    registerValidateOtp,
    validateLoginOtp,
    forgotPassword,
    updatePasswordValidateOtp,
    register,
    logout,
    getUserDetails,
    createNewStudent,
    uploadImage,
    updatePassword,
    updateUserInfo,
    saveUserInfo,
    getProfile,
    logout1,
    resetUser,
    updateProfile,
    getUserInfo,
    getCountryCodeList,
    updateProfilePicture,
    getTicketList,
    createTicket,
    getMsgListByTicketId,
    getAllBoard,
    replyByUserForTicket,
    removeImage,
    clearImage,
    getLanguageList,
    getStudentList,
    previousScoreListing,
    getAllIntroduction,
    getLanguageSetting2List,
    getAllQuestion,
    getAllOption,
    submitAnswer,
    reportDataForStudentId,
    updateUser,
    saveBookingAppointment,
    createOrderpaymentVerify,
    getStudetProfile,
    updateStudent,
};

function clearImage(data) {
    return dispatch => {
        dispatch(success(data));
    };
    function success(data) { return { type: userConstants.CLEAR_STORE_IMAGE, data } }
}

function updateStudent(data) {
    return (dispatch) => {
        dispatch(request());

        // Assuming userService.updateStudent returns a promise
        return userService.updateStudent(data).then(
            (users) => {
                dispatch(alertActions.success("Student updated successfully"));
                dispatch(success(users));
                return users; // Return the result for chaining
            },
            (error) => {
                dispatch(alertActions.error(error));
                dispatch(failure(error));
                return Promise.reject(error); // Return rejected promise for chaining
            }
        );
    };

    function request() {
        return { type: userConstants.UPDATE_STUD_REQUEST };
    }

    function success(users) {
        return { type: userConstants.UPDATE_STUD_SUCCESS, users };
    }

    function failure(error) {
        return { type: userConstants.UPDATE_STUD_FAILURE, error };
    }
}
function getStudetProfile(data) {
    return (dispatch) => {
        dispatch(request());

        // Assuming userService.getStudetProfile returns a promise
        return userService.getStudetProfile(data).then(
            (users) => {
                dispatch(success(users));
                return users; // Return the result for chaining
            },
            (error) => {
                dispatch(alertActions.error(error));
                dispatch(failure(error));
                return Promise.reject(error); // Return rejected promise for chaining
            }
        );
    };

    function request() {
        return { type: userConstants.GET_USER_INFO_REQUEST2 };
    }

    function success(users) {
        return { type: userConstants.GET_USER_INFO_SUCCESS2, users };
    }

    function failure(error) {
        return { type: userConstants.GET_USER_INFO_FAILURE2, error };
    }
}
function createOrderpaymentVerify(data) {
    return (dispatch) => {
        dispatch(request());

        // Assuming userService.createOrderpaymentVerify returns a promise
        return userService.createOrderpaymentVerify(data).then(
            (users) => {
                dispatch(success(users));
                return users; // Return the result for chaining
            },
            (error) => {
                dispatch(alertActions.error(error));
                dispatch(failure(error));
                return Promise.reject(error); // Return rejected promise for chaining
            }
        );
    };

    function request() {
        return { type: userConstants.ROZER_PAY_REQUEST };
    }

    function success(users) {
        return { type: userConstants.ROZER_PAY_SUCCESS, users };
    }

    function failure(error) {
        return { type: userConstants.ROZER_PAY_FAILURE, error };
    }
}


function saveBookingAppointment(data, onClose, setFormData) {
    return (dispatch) => {
        dispatch(request());
        userService.saveBookingAppointment(data).then(
            (users) => {
                dispatch(alertActions.success("Booking Appointment saved successfully"));
                dispatch(success(users));
                onClose()
                setFormData({
                    fullName: '',
                    mobile: '',
                    email: '',
                    date: '',
                    time: '',
                });

            },
            (error) => {
                dispatch(alertActions.error(error));
                dispatch(failure(error));
            }
        );
    };
    function request() {
        return { type: userConstants.SAVE_BOOKING_APPOINTMENT_REQUEST };
    }
    function success(users) {
        return { type: userConstants.SAVE_BOOKING_APPOINTMENT_SUCCESS, users };
    }
    function failure(error) {
        return { type: userConstants.SAVE_BOOKING_APPOINTMENT_FAILURE, error };
    }
}



function updateUser(data, functions) {
    console.log(data)
    console.log(functions)
    return (dispatch) => {
        dispatch(request());
        userService.updateUser(data).then(
            (users) => {
                // // console.log("$$$$$$$$$$$ createTicket $$$$ action:", users);
                dispatch(success(users));
                dispatch(userActions.getProfile());
                functions()

            },
            (error) => {
                dispatch(alertActions.error(error));
                dispatch(failure(error));
            }
        );
    };
    function request() {
        return { type: userConstants.UPDATE_USERS_REQUEST };
    }
    function success(users) {
        return { type: userConstants.UPDATE_USERS_SUCCESS, users };
    }
    function failure(error) {
        return { type: userConstants.UPDATE_USERS_FAILURE, error };
    }
}
function removeImage() {
    return (dispatch) => {
        dispatch(request());
        userService.removeImage().then(
            (users) => {
                dispatch(success(users));
                dispatch(userActions.getProfile());

            },
            (error) => {
                dispatch(alertActions.error(error));
                dispatch(failure(error));
            }
        );
    };
    function request() {
        return { type: userConstants.REMOVE_IMAGE_REQUEST };
    }
    function success(users) {
        return { type: userConstants.REMOVE_IMAGE_SUCCESS, users };
    }
    function failure(error) {
        return { type: userConstants.REMOVE_IMAGE_FAILURE, error };
    }
}



function submitAnswer(data) {
    return (dispatch) => {
        dispatch(request());
        userService.submitAnswer(data)
            .then(
                (users) => {
                    dispatch(success(users));
                },
                (error) => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error));
                }
            );
    };
    function request() {
        return { type: userConstants.SUBMIT_ANSWER_REQUEST };
    }
    function success(users) {
        return { type: userConstants.SUBMIT_ANSWER_SUCCESS, users };
    }
    function failure(error) {
        return { type: userConstants.SUBMIT_ANSWER_FAILURE, error };
    }
}


function reportDataForStudentId(data) {
    return (dispatch) => {
        dispatch(request());
        userService.reportDataForStudentId(data)
            .then(
                (users) => {
                    dispatch(success(users));
                },
                (error) => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error));
                }
            );
    };
    function request() {
        return { type: userConstants.REPORT_DATA_STUDENT_REQUEST };
    }
    function success(users) {
        return { type: userConstants.REPORT_DATA_STUDENT_SUCCESS, users };
    }
    function failure(error) {
        return { type: userConstants.REPORT_DATA_STUDENT_FAILURE, error };
    }
}


function getAllIntroduction(data) {
    return (dispatch) => {
        dispatch(request());
        userService.getAllIntroduction(data)
            .then(
                (users) => {
                    dispatch(success(users));
                },
                (error) => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error));
                }
            );
    };
    function request() {
        return { type: userConstants.USER_INTRODUCTION_LIST_REQUEST };
    }
    function success(users) {
        return { type: userConstants.USER_INTRODUCTION_LIST_SUCCESS, users };
    }
    function failure(error) {
        return { type: userConstants.USER_INTRODUCTION_LIST_FAILURE, error };
    }
}


function getLanguageSetting2List(data) {
    return (dispatch) => {
        dispatch(request());
        userService.getLanguageSetting2List(data)
            .then(
                (users) => {
                    dispatch(success(users));
                },
                (error) => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error));
                }
            );
    };
    function request() {
        return { type: userConstants.USER_LANGUAGE_SETTINGS_LIST_REQUEST };
    }
    function success(users) {
        return { type: userConstants.USER_LANGUAGE_SETTINGS_LIST_SUCCESS, users };
    }
    function failure(error) {
        return { type: userConstants.USER_LANGUAGE_SETTINGS_LIST_FAILURE, error };
    }
}

function getAllQuestion(data) {
    return (dispatch) => {
        dispatch(request());
        userService.getAllQuestion(data)
            .then(
                (users) => {
                    dispatch(success(users));
                },
                (error) => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error));
                }
            );
    };
    function request() {
        return { type: userConstants.ALL_QUESTION_LIST_REQUEST };
    }
    function success(users) {
        return { type: userConstants.ALL_QUESTION_LIST_SUCCESS, users };
    }
    function failure(error) {
        return { type: userConstants.ALL_QUESTION_LIST_FAILURE, error };
    }
}

function getAllOption(data) {
    return (dispatch) => {
        dispatch(request());
        userService.getAllOption(data)
            .then(
                (users) => {
                    dispatch(success(users));
                },
                (error) => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error));
                }
            );
    };
    function request() {
        return { type: userConstants.ALL_OPTION_LIST_REQUEST };
    }
    function success(users) {
        return { type: userConstants.ALL_OPTION_LIST_SUCCESS, users };
    }
    function failure(error) {
        return { type: userConstants.ALL_OPTION_LIST_FAILURE, error };
    }
}


function createTicket(data, resData) {
    // // console.log("data m kya aa rha h::action:::", data);
    return (dispatch) => {
        dispatch(request());
        userService.createTicket(data).then(
            (users) => {
                // // console.log("$$$$$$$$$$$ createTicket $$$$ action:", users);
                dispatch(success(users));
                dispatch(this.getTicketList(resData));
            },
            (error) => {
                dispatch(alertActions.error(error));
                dispatch(failure(error));
            }
        );
    };
    function request() {
        return { type: userConstants.CREATE_TICKET_REQUEST };
    }
    function success(users) {
        return { type: userConstants.CREATE_TICKET_SUCCESS, users };
    }
    function failure(error) {
        return { type: userConstants.CREATE_TICKET_FAILURE, error };
    }
}



function getAllBoard(data) {
    return (dispatch) => {
        dispatch(request());
        userService
            .getAllBoard(data)

            .then(
                (users) => {
                    dispatch(success(users));
                },
                (error) => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error));
                }
            );
    };
    function request() {
        return { type: userConstants.USER_BOARD_LIST_REQUEST };
    }
    function success(users) {
        return { type: userConstants.USER_BOARD_LIST_SUCCESS, users };
    }
    function failure(error) {
        return { type: userConstants.USER_BOARD_LIST_FAILURE, error };
    }
}


function getTicketList(data) {
    return (dispatch) => {
        dispatch(request());
        userService
            .getTicketList(data)

            .then(
                (users) => {
                    dispatch(success(users));
                },
                (error) => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error));
                }
            );
    };
    function request() {
        return { type: userConstants.USER_TICKET_LIST_REQUEST };
    }
    function success(users) {
        return { type: userConstants.USER_TICKET_LIST_SUCCESS, users };
    }
    function failure(error) {
        return { type: userConstants.USER_TICKET_LIST_FAILURE, error };
    }
}


function getLanguageList(data) {
    return (dispatch) => {
        dispatch(request());
        userService.getLanguageList(data)

            .then(
                (users) => {
                    dispatch(success(users));
                },
                (error) => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error));
                }
            );
    };
    function request() {
        return { type: userConstants.USER_LANGUAGE_LIST_REQUEST };
    }
    function success(users) {
        return { type: userConstants.USER_LANGUAGE_LIST_SUCCESS, users };
    }
    function failure(error) {
        return { type: userConstants.USER_LANGUAGE_LIST_FAILURE, error };
    }
}


function getStudentList(data) {
    return (dispatch) => {
        dispatch(request());
        userService.getStudentList(data)

            .then(
                (users) => {
                    dispatch(success(users));
                },
                (error) => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error));
                }
            );
    };
    function request() {
        return { type: userConstants.USER_STUDENT_LIST_REQUEST };
    }
    function success(users) {
        return { type: userConstants.USER_STUDENT_LIST_SUCCESS, users };
    }
    function failure(error) {
        return { type: userConstants.USER_STUDENT_LIST_FAILURE, error };
    }
}


function previousScoreListing(data) {
    return (dispatch) => {
        dispatch(request());
        userService.previousScoreListing(data)

            .then(
                (users) => {
                    dispatch(success(users));
                },
                (error) => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error));
                }
            );
    };
    function request() {
        return { type: userConstants.USER_SCREEN_LIST_REQUEST };
    }
    function success(users) {
        return { type: userConstants.USER_SCREEN_LIST_SUCCESS, users };
    }
    function failure(error) {
        return { type: userConstants.USER_SCREEN_LIST_FAILURE, error };
    }
}

function getMsgListByTicketId(data, temp) {

    return (dispatch) => {
        dispatch(request());
        userService.getMsgListByTicketId(data).then(
            (users) => {
                dispatch(success(users));
                // dispatch(userActions.viewTicketMsg(data))
                // dispatch(userActions.getTicketList(temp));

            },
            (error) => {
                dispatch(alertActions.error(error));
                dispatch(failure(error));
            }
        );
    };
    function request() {
        return { type: userConstants.GETMSGLIST_BY_TICKETID_REQUEST };
    }
    function success(users) {
        return { type: userConstants.GETMSGLIST_BY_TICKETID_SUCCESS, users };
    }
    function failure(error) {
        return { type: userConstants.GETMSGLIST_BY_TICKETID_FAILURE, error };
    }
}


function replyByUserForTicket(data, temp) {
    let id = { ticketId: data.ticketId }
    return (dispatch) => {
        dispatch(request());
        userService.replyByUserForTicket(data).then(
            (users) => {
                dispatch(success(users));
                dispatch(userActions.getMsgListByTicketId(id, temp));
            },
            (error) => {
                dispatch(failure(error));
                dispatch(alertActions.error(error));
            }
        );
    };
    function request() {
        return { type: userConstants.MSG_REPLY_TICKET_BY_USER_TEAM_REQUEST };
    }
    function success(users) {
        return { type: userConstants.MSG_REPLY_TICKET_BY_USER_TEAM_SUCCESS, users };
    }
    function failure(error) {
        return { type: userConstants.MSG_REPLY_TICKET_BY_USER_TEAM_FAILURE, error };
    }
}



function updateProfile(formData, setEditProfile) {
    return dispatch => {
        dispatch(request());
        userService.updateProfile(formData).then(
            users => {
                dispatch(success(users));
                // Alert.alert('Success!', 'Succeessfully Updated');
                dispatch(setEditProfile(true))
                setTimeout(() => {
                    // props.navigation.navigate('Dashboard')
                }, 1000);
            },
            error => {
                dispatch(failure(error));
            },
        );
    };
    function request() { return { type: userConstants.UPDATE_PROFILE_REQUEST }; }
    function success(users) { return { type: userConstants.UPDATE_PROFILE_SUCCESS, users }; }
    function failure(error) { return { type: userConstants.UPDATE_PROFILE_FAILURE, error }; }
}









function updateProfilePicture(data) {
    return dispatch => {
        dispatch(request());
        userService.updateProfilePicture(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(alertActions.success(users.userData.message))
                    dispatch(userActions.getProfile());
                    getProfile()

                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                },
            );
    };
    function request() { return { type: userConstants.UPDATE_PROFILE_PICTURE_REQUEST }; }
    function success(users) { return { type: userConstants.UPDATE_PROFILE_PICTURE_SUCCESS, users }; }
    function failure(error) { return { type: userConstants.UPDATE_PROFILE_PICTURE_FAILURE, error }; }
}






function getCountryCodeList() {
    return dispatch => {
        dispatch(request());
        userService.getCountryCodeList()
            .then(
                users => {
                    console.log('users________________________________users', users);
                    dispatch(success(users));

                },
                error => {
                    dispatch(failure(error));
                },
            );
    };
    function request() { return { type: userConstants.GET_COUNTRY_CODE_LIST_REQUEST } }
    function success(users) { return { type: userConstants.GET_COUNTRY_CODE_LIST_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GET_COUNTRY_CODE_LIST_FAILURE, error } }
}


function getUserInfo(data) {
    return dispatch => {
        dispatch(request());
        userService.getUserInfo(data)
            .then(
                users => {

                    dispatch(success(users));

                },
                error => {
                    dispatch(failure(error));
                },
            );
    };
    function request() { return { type: userConstants.GET_USER_INFO_REQUEST } }
    function success(users) { return { type: userConstants.GET_USER_INFO_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GET_USER_INFO_FAILURE, error } }
}














function resetUser() {
    return dispatch => {
        dispatch(success());
    };
    function success() { return { type: userConstants.RESET_USER_REDUX } }
}
function logout1() {
    userService.logout();
    return { type: userConstants.LOGOUT };
}

function getProfile() {
    return dispatch => {
        dispatch(request());
        userService.getProfile().then(
            users => {
                let addr = users && users.getProfile && users.getProfile.data && users.getProfile.data.wallet && users.getProfile.data && users.getProfile.data.wallet.address
                console.log("getProfilegetProfilegetProfile", addr);
                dispatch(success(users));
            },
            error => {
                dispatch(failure(error));
            },
        );
    };
    function request() { return { type: userConstants.GET_PROFILE_REQUEST }; }
    function success(users) { return { type: userConstants.GET_PROFILE_SUCCESS, users }; }
    function failure(error) { return { type: userConstants.GET_PROFILE_FAILURE, error }; }
}



function updatePassword(data) {
    return dispatch => {
        dispatch(request({ data }));
        userService.updatePassword(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(alertActions.success(users.userinfo.message));
                    dispatch(this.getUserDetails());
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: userConstants.UPDATE_PASSWORD_REQUEST } }
    function success(users) { return { type: userConstants.UPDATE_PASSWORD_SUCCESS, users } }
    function failure(error) { return { type: userConstants.UPDATE_PASSWORD_FAILURE, error } }
}



function login(data) {
    return dispatch => {
        dispatch(request({ data }));
        userService.login(data)
            .then(
                user => {
                    dispatch(success(user));
                    dispatch(alertActions.success(user.userinfo.message));
                    // props.history.push(`/app/dashboard`)
                    // props.history.push(`/app/dashboard`)
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: userConstants.LOGIN_FIRST_REQUEST } }
    function success(user) { return { type: userConstants.LOGIN_FIRST_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FIRST_FAILURE, error } }
}

function updatePasswordValidateOtp(data) {
    // console.log("updatePasswordValidateOtp________ACTION::", data);
    return dispatch => {
        dispatch(request({ data }));
        userService.updatePasswordValidateOtp(data)
            .then(
                user => {
                    dispatch(success(user));
                    dispatch(alertActions.success(user.userinfo.message));
                    dispatch(this.getUserDetails());
                    // props.history.push(`/app/dashboard`)
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.UPDATE_PASSWORD_VALIDATE_OTP_REQUEST, user } }
    function success(user) { return { type: userConstants.UPDATE_PASSWORD_VALIDATE_OTP_SUCCESS, user } }
    function failure(error) { return { type: userConstants.UPDATE_PASSWORD_VALIDATE_OTP_FAILURE, error } }
}

function validateLoginOtp(data, props) {
    return dispatch => {
        dispatch(request({ data }));
        userService.validateLoginOtp(data)
            .then(
                user => {
                    dispatch(success(user));
                    dispatch(alertActions.success(user.userinfo.message));
                    props.history.push(`/app/dashboard`)
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.VALIDATE_LOGIN_OTP_REQUEST, user } }
    function success(user) { return { type: userConstants.VALIDATE_LOGIN_OTP_SUCCESS, user } }
    function failure(user) { return { type: userConstants.VALIDATE_LOGIN_OTP_FAILURE, user } }
}
function loginValidateOtp(data, props) {
    return dispatch => {
        dispatch(request({ data }));
        userService.loginValidateOtp(data)
            .then(
                async user => {
                    dispatch(success(user));
                    // console.log('User successfully', user);
                    dispatch(alertActions.success(user.userinfo.message));
                    const res = await userService.getProfile();
                    if (res?.getProfile?.data?.email && res?.getProfile?.data?.fullName && res?.getProfile?.data?.type) {
                        props.history.push(`/app/dashboard`)
                    }
                    else if (user?.userinfo?.data.mobNo) {
                        props.history.push(`/signup/${user?.userinfo?.data.mobNo}`)
                    } else if (user?.userinfo?.data.email) {
                        props.history.push(`/signup/${user?.userinfo?.data.email}`)

                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}


function forgotPassword(data) {
    return dispatch => {
        dispatch(request({ data }));
        userService.forgotPassword(data)
            .then(
                user => {
                    console.log('user_______', user);
                    dispatch(success(user));

                    dispatch(alertActions.success(user.userinfo.message));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.FORGOT_FIRST_REQUEST, user } }
    function success(user) { return { type: userConstants.FORGOT_FIRST_SUCCESS, user } }
    function failure(error) { return { type: userConstants.FORGOT_FIRST_FAILURE, error } }
}
function register(data) {
    return dispatch => {
        dispatch(request({ data }));
        userService.register(data)
            .then(
                user => {
                    dispatch(success(user));
                    dispatch(alertActions.success(user.userinfo.message));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.REGISTER_FIRST_REQUEST, user } }
    function success(user) { return { type: userConstants.REGISTER_FIRST_SUCCESS, user } }
    function failure(error) { return { type: userConstants.REGISTER_FIRST_FAILURE, error } }
}

function registerValidateOtp(data, props) {

    console.log(data);

    return dispatch => {
        dispatch(request({ data }));
        userService.registerValidateOtp(data)
            .then(
                user => {

                    console.log("otpsumbit______", user);
                    dispatch(success(user));
                    dispatch(alertActions.success(user.userinfo.message));
                    props.history.push(`/app/dashboard`)
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: userConstants.LOGIN_REQUEST } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}
function logout() {
    return dispatch => {
        dispatch(dashboardActions.resetDashboard());
        dispatch(this.resetUser());
        dispatch(this.logout1());
    };
}

function createNewStudent(data, props) {
    return dispatch => {
        dispatch(request({ data }));
        userService.createNewStudent(data)
            .then(
                user => {
                    dispatch(success(user));
                    dispatch(alertActions.success("Student Added"))
                    props.history.push("/app/dashboard")
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.CREATE_STUDENT_REQUEST, user } }
    function success(user) { return { type: userConstants.CREATE_STUDENT_SUCCESS, user } }
    function failure(error) { return { type: userConstants.CREATE_STUDENT_FAILURE, error } }
}







function getUserDetails() {

    return dispatch => {
        dispatch(request());
        userService.getUserDetails()
            .then(
                users => {
                    // console.log("ACTION__getUserDetails___getUserDetails::", users);
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.USER_DETAILS_REQUEST } }
    function success(users) { return { type: userConstants.USER_DETAILS_SUCCESS, users } }
    function failure(error) { return { type: userConstants.USER_DETAILS_FAILURE, error } }
}




function updateUserInfo(data) {

    return dispatch => {
        dispatch(request());
        userService.updateUserInfo(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(alertActions.success(users.updateUserInfo.message));
                    dispatch(this.getUserDetails());
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.UPDATE_USER_DETAILS_REQUEST } }
    function success(users) { return { type: userConstants.UPDATE_USER_DETAILS_SUCCESS, users } }
    function failure(error) { return { type: userConstants.UPDATE_USER_DETAILS_FAILURE, error } }
}















function saveUserInfo(data, props) {
    return dispatch => {
        dispatch(request());
        userService.saveUserInfo(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(alertActions.success("Success"));
                    dispatch(userActions.getUserDetails());
                    props.history.push('/app/dashboard');
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.SAVE_USER_INFO_REQUEST } }
    function success(users) { return { type: userConstants.SAVE_USER_INFO_SUCCESS, users } }
    function failure(error) { return { type: userConstants.SAVE_USER_INFO_FAILURE, error } }
}



function uploadImage(data) {
    return dispatch => {
        userService.uploadImage(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(alertActions.success('Image upload successfully'))
                },
                error => {

                    dispatch(alertActions.error("File size should not more than 1 mb"));
                    dispatch(failure(error))
                }
            );
    };
    function success(users) { return { type: userConstants.FILE_UPLOAD_STATUS_SUCCESS, users } }
    function failure(error) { return { type: userConstants.FILE_UPLOAD_STATUS_FAILURE, error } }
}














































