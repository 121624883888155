import React from "react";
import OtpTimer from 'otp-timer';

const Otp = ({handleClick}) => {


  return (
    <div>
      <OtpTimer
      className="otp-timer"
       background="none"
        seconds={30}
        minutes={0}
        buttonColor="red"
        resend={handleClick}
          />
    </div>
  );
};

export default Otp;
