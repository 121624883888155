import React, { Component } from 'react';
import { connect } from 'react-redux';
import { MdOutlineChevronLeft } from 'react-icons/md'
class ChangePassword extends Component {

  onClickMenu = (url) => {
    this.props.history.push(url);
  };

  render() {

    return (
      <>
        <div className='overflow-hidden overflow-y-auto'>
          <div className='lg:w-7/12 w-11/12 mx-auto py-10'>
            <div className='bg-[#F6F0FF] px-2 py-4 space-y-6'>
              <div className="flex items-center justify-between p-6 py-2">
                <div className="rounded-full p-1 bg-[#fff] shadow-lg" onClick={() => this.onClickMenu('/app/dashboard')}>
                  <MdOutlineChevronLeft className='text-[#4E2C7A]' size={30} />
                </div>
                <div className="">
                  <h1>Acknowledgements</h1>
                </div>
                <div>
                </div>
              </div>
            </div>
            <div className='flex flex-col py-10 space-y-4'>
              <div className='space-y-6'>
                <h1 className='text-[#4E2C7A] text-[24px] font-[700]'>Our sincere gratitude for their support, guidance & belief in our cause</h1>
                <div>
                  <p className='text-[18px] font-[700]'>Dr. Jayanthi Narayan</p>
                  <p className=''>Ex-Deputy Director of the National Institute for the Mentally Handicapped (NIMH)</p>
                </div>
                <div>
                  <p className='text-[18px] font-[700]'>Prof Catherine Snow</p>
                  <p className=''>John and Elisabeth Hobbs Professor of Cognition and Education, Harvard Graduate School of Education & Our CSR Partner</p>
                </div>
                <div>
                  <p className='text-[18px] font-[700]'>Mr. Tehmasp Printer</p>
                  <p className=''>International Gemological Institute for his belief in our cause</p>
                </div>
              </div>
              <h1 className='py-4 text-[#4E2C7A] text-[24px] font-[700] text-center'>Translation Team</h1>
              <div class="relative overflow-x-auto">
                <table className="w-full ">
                  <thead className="">
                    <tr className=" border">
                      <th scope="col" className="px-6 py-3 text-black text-lg">Name</th>
                      <th scope="col" className="px-6 py-3 text-black text-lg border border-r">Language</th>
                    </tr>
                  </thead>
                  <tbody className='border'>
                    <tr className="">
                      <th className="px-6 py-4 text-gray-600 border border-r">
                        Dr Arundhati Sarkar
                      </th>
                      <td className="px-6 py-4 text-gray-600 border border-r">Bengali</td>
                    </tr>
                    <tr className="">
                      <th className="px-6 py-4 text-gray-600 border border-r">
                        Jayashree Rajanahally
                      </th>
                      <td className="px-6 py-4 text-gray-600 border border-r">Kannada</td>
                    </tr>
                    <tr className="">
                      <th className="px-6 py-4 text-gray-600 border border-r">
                        Farida Raj
                      </th>
                      <td className="px-6 py-4 text-gray-600 border border-r">Urdu</td>
                    </tr>
                    <tr className="">
                      <th className="px-6 py-4 text-gray-600 border border-r">
                        Devyani Borkataki
                      </th>
                      <td className="px-6 py-4 text-gray-600 border border-r">Assamese</td>
                    </tr>
                    <tr className="">
                      <th className="px-6 py-4 text-gray-600 border border-r">
                        Dr. Simran Randhawa
                      </th>
                      <td className="px-6 py-4 text-gray-600 border border-r">Punjabi</td>
                    </tr>
                    <tr className="">
                      <th className="px-6 py-4 text-gray-600 border border-r">
                        Dr. C. Renuga Devi
                      </th>
                      <td className="px-6 py-4 text-gray-600 border border-r">Tamil</td>
                    </tr>
                    <tr className="">
                      <th className="px-6 py-4 text-gray-600 border border-r">
                        Vijaya Gutha Kumar
                      </th>
                      <td className="px-6 py-4 text-gray-600 border border-r">Telugu</td>
                    </tr>
                    <tr className="">
                      <th className="px-6 py-4 text-gray-600 border border-r">
                        Dr. Jayendrakumar N. Amin
                      </th>
                      <td className="px-6 py-4 text-gray-600 border border-r">Gujarati</td>
                    </tr>
                    <tr className="">
                      <th className="px-6 py-4 text-gray-600 border border-r">
                        Dr. Ravi K. M
                      </th>
                      <td className="px-6 py-4 text-gray-600 border border-r">Malayalam</td>
                    </tr>
                    <tr className="">
                      <th className="px-6 py-4 text-gray-600 border border-r">
                        Dr.Apoorva Panshikar
                      </th>
                      <td className="px-6 py-4 text-gray-600 border border-r">Marathi</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

      </>
    );
  }
}
function mapStateToProps(state) {
  const { users } = state;
  const { overview } = users ? users : {};
  const { user } = overview ? overview : {};
  return {
    users,
    user
  };
}
export default connect(mapStateToProps)(ChangePassword);
