import React, { Component } from 'react';
import { MdOutlineChevronLeft, MdChevronLeft, MdChevronRight } from 'react-icons/md';
import { connect } from 'react-redux';
// import LoadingOverlay from 'react-loading-overlay';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { userActions } from '../../_actions';

const PrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div className="slick-arrow rounded-full bg-black slick-prev ml-6" onClick={onClick}>
      <MdChevronLeft />
    </div>
  );
};

const NextArrow = (props) => {
  const { onClick } = props;
  return (
    <div className="slick-arrow rounded-full bg-black slick-next mr-6" onClick={onClick}>
      <MdChevronRight />
    </div>
  );
};

const sliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  prevArrow: <PrevArrow />,
  nextArrow: <NextArrow />,
};

class Introduction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedLanguage: '', // Assuming you will set this somewhere
      introductionSkip: false,
      isChecked: false,
      currentSlide: 0,
    };
    this.sliderRef = React.createRef();
  }

  componentDidMount() {
    this.props.dispatch(userActions.getAllIntroduction()); // Ensure userActions.getAllIntroduction() is correctly defined
  }


  static getDerivedStateFromProps(nextProps, prevState) {
    function arrayToObjectFunctionLanguage(arr) {
      let obj = {}
      for (let i = 0; i < arr.length; i++) {
        obj[arr[i].name] = arr[i]["value"][window.localStorage.getItem("language")]
      }
      return obj
    }
    if (nextProps.users && nextProps.users.getLanguageSetting2List && Array.isArray(nextProps.users.getLanguageSetting2List)) {
      const objects = arrayToObjectFunctionLanguage(nextProps.users.getLanguageSetting2List)
      window.sessionStorage.setItem('languageSetting2List', JSON.stringify(objects))
    }
  }

  onClickMenuButton = (url) => {
    this.props.history.push(url);
  };

  handelChecked = () => {
    this.setState({
      isChecked: !this.state.isChecked,
    });
  };

  handelStartButton = () => {
    this.setState({
      introductionSkip: true, // Update introductionSkip to true to render the next part
    });
  };

  handelSkipButton = () => {
    const { getAllIntroduction } = this.props.users;
    if (this.sliderRef.current) {
      this.sliderRef.current.slickGoTo(getAllIntroduction.length - 1);
      this.setState({ currentSlide: getAllIntroduction.length - 1 });
    }
  };

  render() {
    const { users } = this.props;
    const { loading, getAllIntroduction } = users;

    return (
      <>
        <div>
          {/* <LoadingOverlay active={loading} className="temp001" spinner text="Please wait..." /> */}
        </div>

        {!this.state.introductionSkip ? (
          <div className="h-screen xl:py-10 py-6 overflow-y-auto item-center flex justify-center">
            <div className="xl:w-7/12 w-11/12 mx-auto py-10">
              <div className="bg-[#F6F0FF] px-2 py-4 space-y-6">
                <div className="flex items-center justify-between p-6 py-2">
                  <div className="rounded-full p-1 bg-[#fff] shadow-lg" onClick={() => this.props.history.goBack()}>
                    <MdOutlineChevronLeft className="text-[#4E2C7A]" size={30} />
                  </div>
                  <div>
                    <h1>{window.sessionStorage.getItem("languageSetting2List") ? (JSON.parse(window.sessionStorage.getItem("languageSetting2List")))["Introduction"] : "Introduction"}</h1>
                  </div>
                  <div>

                  </div>
                </div>
              </div>
              <Slider ref={this.sliderRef} {...sliderSettings}>
                {getAllIntroduction && Array.isArray(getAllIntroduction) && getAllIntroduction.length > 0 && getAllIntroduction.map((ele, index) => (
                  <>
                    <div className="text-center px-6" key={index}>
                      <div className="py-10 space-y-2 lg:sm text-xs" dangerouslySetInnerHTML={{ __html: ele.content[window.localStorage.getItem("language")] || '' }}></div>
                      {ele.image[window.localStorage.getItem("language")] && (
                        <img src={ele.image[window.localStorage.getItem("language")]} alt="mmm" className="mx-auto" />
                      )}
                    </div>
                    {index !== getAllIntroduction.length - 1 ? (
                      <div className="flex justify-center items-center px-6">
                        <button
                          type="button"
                          className="text-white bg-[#4E2C7A] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-96 px-5 py-2.5 text-center dark:hover:bg-[#4E2C7A]"
                          onClick={this.handelSkipButton}
                        >
                          {window.sessionStorage.getItem("languageSetting2List") ? (JSON.parse(window.sessionStorage.getItem("languageSetting2List")))["Skip"] : "Skip"}
                        </button>
                      </div>
                    ) : (
                      <div className="flex justify-center items-center px-6">
                        <button
                          type="button"
                          onClick={this.handelStartButton}
                          className="text-white bg-[#4E2C7A] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-96 px-5 py-2.5 text-center dark:hover:bg-[#4E2C7A]"
                        >
                          {window.sessionStorage.getItem("languageSetting2List") ? (JSON.parse(window.sessionStorage.getItem("languageSetting2List")))["Start_Screening"] : "Start Screening"}
                        </button>
                      </div>
                    )}
                  </>
                ))}
              </Slider>
            </div>
          </div>
        ) : (
          <div className="lg:w-7/12 w-11/12 mx-auto space-y-2">
            <div className="bg-[#F6F0FF] px-2 py-4 space-y-6">
              <div className="flex items-center justify-between p-6 py-2">
                <div className="rounded-full p-1 bg-[#fff] shadow-lg" onClick={() => this.props.history.goBack()}>
                  <MdOutlineChevronLeft className="text-[#4E2C7A]" size={30} />
                </div>
                <div>
                  <h1>{window.sessionStorage.getItem("languageSetting2List") ? (JSON.parse(window.sessionStorage.getItem("languageSetting2List")))["Introduction"] : "Introduction"}</h1>
                </div>
                <div>

                </div>
              </div>
            </div>
            <div className="py-10 space-y-4">
              <p>{window.sessionStorage.getItem("languageSetting2List") ? (JSON.parse(window.sessionStorage.getItem("languageSetting2List")))["Please_read_the_questionnaire/statement_thoroughly_and_select_the_appropriate_response"] : "Please read the questionnaire/statement thoroughly and select the appropriate response."}</p>
              <p>{window.sessionStorage.getItem("languageSetting2List") ? (JSON.parse(window.sessionStorage.getItem("languageSetting2List")))["You_will_not_be_able_to_go_back_and_alter_your_response."] : "You will not be able to go back and alter your response."}</p>
              <h1 className="text-[#4E2C7A] text-[26px] font-[600]">{window.sessionStorage.getItem("languageSetting2List") ? (JSON.parse(window.sessionStorage.getItem("languageSetting2List")))["How_to_answer?"] : "How to answer?"}</h1>
            </div>
            <div className="bg-[#F6F0FF] rounded-[16px] space-y-2 p-6">
              <h1>{window.sessionStorage.getItem("languageSetting2List") ? (JSON.parse(window.sessionStorage.getItem("languageSetting2List")))["YES"] : "YES"}</h1>
              <p>
                {window.sessionStorage.getItem("languageSetting2List") ? (JSON.parse(window.sessionStorage.getItem("languageSetting2List")))["if_the_child_has_been_showing_persistent_difficulties_in_the_mentioned_skill_or_behaviour_most_of_the_time_in_the_past_6_months_and_it_is_affecting_school_ performance_significantly_For_example,_while_reading_the_child_is_not_able_to_decode_(break)_words_into_sounds_without_help."] : "If the child has been showing persistent difficulties in the mentioned skill or behavior most of the time in the past 6 months and it is affecting school performance significantly. For example, while reading the child is not able to decode (break) words into sounds without help."}

              </p>
            </div>
            <div className="bg-[#F6F0FF] rounded-[16px] space-y-2 p-6">
              <h1>{window.sessionStorage.getItem("languageSetting2List") ? (JSON.parse(window.sessionStorage.getItem("languageSetting2List")))["NO"] : "No"}</h1>
              <p>
                {window.sessionStorage.getItem("languageSetting2List") ? (JSON.parse(window.sessionStorage.getItem("languageSetting2List")))["if_the_child_has_hardly_ever_shown_difficulties_in_the_mentioned-skill_or_behaviour_in_the_past_6_months_and_it_has_no_impact_on_school_performance._For_example,_while_reading_the_child_is_able_to_decode_(break)_the_word_into_sounds_easily."] : "If the child has hardly ever shown difficulties in the mentioned skill or behavior in the past 6 months and it has no impact on school performance. For example, while reading the child is able to decode (break) the word into sounds easily."}
              </p>
            </div>
            <div className="py-2">
              <input type="checkbox" checked={this.state.isChecked} onChange={this.handelChecked} />
              <span> {window.sessionStorage.getItem("languageSetting2List") ? (JSON.parse(window.sessionStorage.getItem("languageSetting2List")))["I_understand"] : "I Understood"}</span>
            </div>
            <div className="py-2">
              <button
                type="button"
                disabled={!this.state.isChecked}
                className={`text-white ${this.state.isChecked ? "bg-[#4E2C7A]" : "bg-[#bda9d8]"} focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-40 px-5 py-2.5 text-center dark:hover:bg-[#4E2C7A]`}
                onClick={() => this.onClickMenuButton(`/app/pre-assessment/${this.props.match.params.id}`)}
              >
                {window.sessionStorage.getItem("languageSetting2List") ? (JSON.parse(window.sessionStorage.getItem("languageSetting2List")))["Let's_Start"] : "Let's Start"}
              </button>
            </div>
          </div>
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  const { users } = state;
  return {
    users,
  };
}

export default connect(mapStateToProps)(Introduction);
