import React, { Component } from 'react';
import { userActions } from '../../_actions';
import { connect } from 'react-redux';
import "jspdf-autotable";
import { FaMedal, FaEdit } from "react-icons/fa";
import { confirmAlert } from 'react-confirm-alert';
import { FiChevronRight } from "react-icons/fi";
import ReceiveModal from './components/ReceiveModal/ReceiveModal';

class Dashboard extends Component {

  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      pageNo: 1,
      size: 10,
      fieldslogin: {},
      errorslogin: {},
      option: {},
      updateinfo: {},
      openText: false,
    };
    this.clickRef = React.createRef(); // Move this outside of the state assignment
  }


  componentDidMount() {

    const data = {
      "keyWord": "",
      "pageNo": 1,
      "sortBy": "createdAt",
      "sortOrder": "asc",
      "fromDate": "",
      "toDate": "",
      "size": 1000
    }
    this.props.dispatch(userActions.getProfile());
    this.props.dispatch(userActions.getStudentList(data))
    // this.props.dispatch(userActions.getUserWalletToBuy(tempsData))
    window.scrollTo(0, 0)
  }

  static getDerivedStateFromProps(nextProps) {
    if (nextProps.users.sendCoinSuccess) {
      return {
        ...nextProps,
        fieldslogin: {},
        errorslogin: {}
      }
    } else {
      return {
        ...nextProps
      }
    }
  }

  onClickMenu = (url) => {
    this.props.history.push(url)
  }

  handleBuyPackage = (data) => {

    confirmAlert({
      title: 'Confirm to buy package?',
      p: `Are you sure to spend ${data.price} $?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            // this.props.dispatch(userActions.getProfileDataPi())
          }
        },
        {
          label: 'No'
        }
      ]
    });
  }


  handleOpenReceive = (elem) => {
    this.setState({ isReceive: true, updateinfo: elem })
    this.props.dispatch(userActions.clearImage())
  };

  handleCloseReceive = () => {
    this.setState({ isReceive: false })
    this.props.dispatch(userActions.clearImage())

  }



  handelOnRoute = (id) => {
    this.props.history.push(`/app/previous-screening/${id}`)
  }



  handelOnClickAddStudent = () => {
    this.props.history.push("/app/AddContract")
  }

  handleClickFile = () => {
    if (this.clickRef.current) {
      this.clickRef.current.click();
    }
  };
  handleChangePhoto = (e) => {
    const formData = new FormData();
    formData.append("image", e.target.files[0]);
    // this.setState({ imageUpload: true });
    this.props.dispatch(userActions.uploadImage(formData));
  }

  handleChange = (e) => {
    let { name, value } = e.target;
    this.setState({
      updateinfo: { ...this.state.updateinfo, [name]: value }
    })
  }
  handleSubmit = (e) => {
    e.preventDefault();
    let { users } = this.props
    let { filesDetails } = users ? users : {}
    let { imageURL } = filesDetails ? filesDetails : {}

    let Obj = {
      "userId": this.state.updateinfo && this.state.updateinfo?._id,
      "fullName": this.state.updateinfo?.fullName,
      "email": this.state.updateinfo?.email,
      "profileImg": imageURL
    }
    console.log(Obj)

    this.props.dispatch(userActions.updateUser(Obj, this.handleCloseReceive))


  }




  render() {
    let { users } = this.props;
    let { getProfile, studentList, studentTotal } = users;
    // console.log("studentList?.studentList?.list", studentTotal)
    return (
      <>
        <section className='overflow-hidden overflow-y-auto  lg:pb-0 pb-20'>
          <div className='xl:w-7/12 w-11/12 mx-auto py-10'>
            <div className=' rounded-[14px] p-6'>
              <div className='flex items-center w-full lg:space-x-6 space-x-4'>
                <div className=' lg:w-fit w-full'>
                  <img src={getProfile && getProfile && getProfile.profileImg ? getProfile.profileImg : '/img/Image.png'} alt="profile" className='md:w-40  w-32 h-32 md:h-40 md:rounded-[16px] rounded-full ring-[#F6F0FF] ring-1 shadow-md' />
                </div>
                <div className=' lg:w-fit w-full space-y-2'>
                  <div>
                    <div className='flex items-center gap-2'>
                      <h1 className='lg:text-3xl text-xl font-bold '>{getProfile && getProfile && getProfile.fullName ? getProfile.fullName : ''} </h1>
                      <span onClick={() => this.handleOpenReceive(getProfile)}><FaEdit size={20} /></span>
                    </div>
                    <p className='lg:text-xl text-md font-semibold text-[#231F20]'>{getProfile && getProfile && getProfile.mobNo ? getProfile.mobNo : ''}</p>
                    <p className='lg:text-xl text-md font-semibold text-[#231F20]'>{getProfile && getProfile && getProfile.email ? getProfile.email : ''}</p>
                  </div>
                  <div className='flex items-center space-x-2'>
                    <FaMedal color='#FFA000' size={30} />

                    <div className='bg-[#4E2C7A] w-8 h-8 rounded-full  flex justify-center items-center'>
                      {/* {console.log(getProfile && getProfile && getProfile.total)} */}
                      <span className='text-white'>{studentTotal || "0"}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='space-y-4 pb-6'>
              {Array.isArray(studentList?.studentList?.list) && studentList?.studentList?.list.length > 0 && studentList?.studentList?.list.map((ele) => {
                return (<div key={ele._id} className='flex justify-between items-center border-b border-[#D9D9D975] cursor-pointer bg-[#F6F0FF] p-4 rounded-[16px]' onClick={() => this.handelOnRoute(ele._id)}>
                  <div className='flex flex-col space-y-1'>
                    <div className='flex items-center space-x-2'>
                      <div className=' text-white rounded-full w-14 h-14 flex justify-center items-center'>
                        <img src={ele && ele && ele.profileImg ? ele.profileImg : '/img/Image.png'} alt={ele.fullName} className='rounded-full w-14 h-14' />
                      </div>
                      <div>
                        <p className='text-[#000] text-[16px] font-[600]'>{ele.fullName}</p>
                        <p className='text-[#231F20] text-[14px] font-[400]'>{ele.grade}</p>
                      </div>
                    </div>
                  </div>
                  <div>
                    <FiChevronRight color='#4E2C7A' size={30} />
                  </div>
                </div>
                )
              })}
            </div>


          </div>
        </section>
        <div className='flex justify-end items-center cursor-pointer relative lg:w-7/12 w-11/12 mx-auto'>
          <div className='bg-[#4E2C7A] w-10 h-10 rounded-full  flex justify-center items-center absolute bottom-3' onClick={() => this.handelOnClickAddStudent()}>
            <span className='text-white text-2xl'>+</span>
          </div>
        </div>


        <ReceiveModal
          handleChange={this.handleChange}
          handleCloseReceive={this.handleCloseReceive}
          handleOpenPayment={this.state.isReceive}
          handleClickFile={this.handleClickFile}
          clickRef={this.clickRef}
          handleChangePhoto={this.handleChangePhoto}
          props={this.props}
          handleSubmit={this.handleSubmit}
          updateinfo={this.state.updateinfo}
        />

      </>
    );
  }
}

function mapStateToProps(state) {
  const { users, dashboard } = state;
  const { overview } = users ? users : {};
  const { setting, user, wallet } = overview ? overview : {};
  return {
    users,
    setting,
    user,
    dashboard,
    wallet
  };
}

export default connect(mapStateToProps)(Dashboard);

