import { dashboardConstants } from '../_constants';
import { dashboardService } from '../_services';
import { alertActions, userActions } from '.';


export const dashboardActions = {
    resetDashboard,
    getAllEmoji,
    adddashboard,
    deletedashboard,
    getAllResourceList,
    saveAssignResourcedashboard,
    getAssignedResourceList,
    udpateStatus,
    getCoinList,
    getLoginhistory,
    getAllCoinCard,
    getMiningPftShareList,
    getWelcomeBonusList,
    getLTArchiveRewardList,
    getFastrackBonusList,
    buyPackage,
    getUserPackagesTeam,
    getUserPackagesTeamTotalCount,
    getUserPackages,
    getCmcHistoryDftcList,
    getTxAllMultiTeam,
    getTxAllMulti,
    getAllLevel,
    convertToMainBalance,
    getAppSetting,
    getTeamUserPackageLastLevelById,
};

function getAppSetting() {
    return dispatch => {
        dispatch(request());
        dashboardService.getAppSetting()
            .then(
                dashboards => {
                    dispatch(success(dashboards));
                    dispatch(userActions.getUserDetails());

                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));

                }
            );
    };
    function request() { return { type: dashboardConstants.GET_APP_SETTING_REQUEST } }
    function success(dashboards) { return { type: dashboardConstants.GET_APP_SETTING_SUCCESS, dashboards } }
    function failure(error) { return { type: dashboardConstants.GET_APP_SETTING_FAILURE, error } }
}
function getTeamUserPackageLastLevelById() {
    return dispatch => {
        dispatch(request());
        dashboardService.getTeamUserPackageLastLevelById()
            .then(
                dashboards => {
                    dispatch(success(dashboards));
                    dispatch(userActions.getUserDetails());

                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));

                }
            );
    };
    function request() { return { type: dashboardConstants.GET_TEAM_PACKAGE_LAST_LEVEL_REQUEST } }
    function success(dashboards) { return { type: dashboardConstants.GET_TEAM_PACKAGE_LAST_LEVEL_SUCCESS, dashboards } }
    function failure(error) { return { type: dashboardConstants.GET_TEAM_PACKAGE_LAST_LEVEL_FAILURE, error } }
}

function convertToMainBalance() {
    return dispatch => {
        dispatch(request());
        dashboardService.convertToMainBalance()
            .then(
                dashboards => {
                    dispatch(success(dashboards));
                    dispatch(userActions.getUserDetails());

                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));

                }
            );
    };
    function request() { return { type: dashboardConstants.GET_CONVERT_MAIN_BALANCE_REQUEST } }
    function success(dashboards) { return { type: dashboardConstants.GET_CONVERT_MAIN_BALANCE_SUCCESS, dashboards } }
    function failure(error) { return { type: dashboardConstants.GET_CONVERT_MAIN_BALANCE_FAILURE, error } }
}
function getAllLevel(data) {
    return dispatch => {
        dispatch(request());
        dashboardService.getAllLevel(data)
            .then(
                dashboards => {
                    dispatch(success(dashboards))
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_ALL_LEVEL_REQUEST } }
    function success(dashboards) { return { type: dashboardConstants.GET_ALL_LEVEL_SUCCESS, dashboards } }
    function failure(error) { return { type: dashboardConstants.GET_ALL_LEVEL_FAILURE, error } }
}
function getTxAllMulti(data) {
    return dispatch => {
        dispatch(request());
        dashboardService.getTxAllMulti(data)
            .then(
                dashboards => {
                    dispatch(success(dashboards))
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_TX_ALL_MULTI_REQUEST } }
    function success(dashboards) { return { type: dashboardConstants.GET_TX_ALL_MULTI_SUCCESS, dashboards } }
    function failure(error) { return { type: dashboardConstants.GET_TX_ALL_MULTI_FAILURE, error } }
}

function getTxAllMultiTeam(data) {
    return dispatch => {
        dispatch(request());
        dashboardService.getTxAllMultiTeam(data)
            .then(
                dashboards => {
                    dispatch(success(dashboards))
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_TX_ALL_MULTI_TEAM_REQUEST } }
    function success(dashboards) { return { type: dashboardConstants.GET_TX_ALL_MULTI_TEAM_SUCCESS, dashboards } }
    function failure(error) { return { type: dashboardConstants.GET_TX_ALL_MULTI_TEAM_FAILURE, error } }
}

function getAllEmoji(data) {
    return dispatch => {
        dispatch(request());
        dashboardService.getAllEmoji(data)
            .then(
                dashboards => {
                    dispatch(success(dashboards))
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GETALL_DASHBOARD_REQUEST } }
    function success(dashboards) { return { type: dashboardConstants.GETALL_DASHBOARD_SUCCESS, dashboards } }
    function failure(error) { return { type: dashboardConstants.GETALL_DASHBOARD_FAILURE, error } }
}

function buyPackage(data) {
    return dispatch => {
        dispatch(request());
        dashboardService.buyPackage(data)
            .then(
                dashboards => {

                    dispatch(success(dashboards));
                },
                error => {
                    dispatch(failure(error))
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request() { return { type: dashboardConstants.BUY_PACKAGE_REQUEST } }
    function success(dashboards) { return { type: dashboardConstants.BUY_PACKAGE_SUCCESS, dashboards } }
    function failure(error) { return { type: dashboardConstants.BUY_PACKAGE_FAILURE, error } }
}

function getUserPackages() {
    return dispatch => {
        dispatch(request());
        dashboardService.getUserPackages()

            .then(
                users => {
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_USER_PACKAGES_REQUEST } }
    function success(users) { return { type: dashboardConstants.GET_USER_PACKAGES_SUCCESS, users } }
    function failure(error) { return { type: dashboardConstants.GET_USER_PACKAGES_FAILURE, error } }
}

function getLoginhistory(data) {
    return dispatch => {
        dispatch(request());
        dashboardService.getLoginhistory(data)
            .then(
                dashboards => {
                    dispatch(success(dashboards))
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_LOGIN_HISTORY_REQUEST } }
    function success(dashboards) { return { type: dashboardConstants.GET_LOGIN_HISTORY_SUCCESS, dashboards } }
    function failure(error) { return { type: dashboardConstants.GET_LOGIN_HISTORY_FAILURE, error } }
}

function getCoinList(data) {
    // console.log('data111111111111111111111111111111', data);
    return dispatch => {
        dispatch(request());
        dashboardService.getCoinList(data)
            .then(
                dashboards => {
                    dispatch(success(dashboards))
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_COIN_LIST_REQUEST } }
    function success(dashboards) { return { type: dashboardConstants.GET_COIN_LIST_SUCCESS, dashboards } }
    function failure(error) { return { type: dashboardConstants.GET_COIN_LIST_FAILURE, error } }
}

function getCmcHistoryDftcList(data) {
    return dispatch => {
        dispatch(request());
        dashboardService.getCmcHistoryDftcList(data)
            .then(
                dashboards => {
                    // console.log("dashboardsdashboardsdashboards_____", dashboards)
                    dispatch(success(dashboards))

                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_CMC_HISTORY_DFTC_LIST_REQUEST } }
    function success(dashboards) { return { type: dashboardConstants.GET_CMC_HISTORY_DFTC_LIST_SUCCESS, dashboards } }
    function failure(error) { return { type: dashboardConstants.GET_CMC_HISTORY_DFTC_LIST_FAILURE, error } }
}

function getAllCoinCard(data) {
    return dispatch => {
        dispatch(request());
        dashboardService.getAllCoinCard(data)
            .then(
                dashboards => {
                    dispatch(success(dashboards))
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_ALL_COIN_CARD_REQUEST } }
    function success(dashboards) { return { type: dashboardConstants.GET_ALL_COIN_CARD_SUCCESS, dashboards } }
    function failure(error) { return { type: dashboardConstants.GET_ALL_COIN_CARD_FAILURE, error } }
}

function getMiningPftShareList(data) {
    return dispatch => {
        dispatch(request());
        dashboardService.getMiningPftShareList(data)
            .then(
                dashboards => {
                    dispatch(success(dashboards))
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_MINING_PROFIT_SHARE_LIST_REQUEST } }
    function success(dashboards) { return { type: dashboardConstants.GET_MINING_PROFIT_SHARE_LIST_SUCCESS, dashboards } }
    function failure(error) { return { type: dashboardConstants.GET_MINING_PROFIT_SHARE_LIST_FAILURE, error } }
}

function getWelcomeBonusList(data) {
    return dispatch => {
        dispatch(request());
        dashboardService.getWelcomeBonusList(data)
            .then(
                dashboards => {
                    dispatch(success(dashboards))
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_WELCOME_BONUS_LIST_REQUEST } }
    function success(dashboards) { return { type: dashboardConstants.GET_WELCOME_BONUS_LIST_SUCCESS, dashboards } }
    function failure(error) { return { type: dashboardConstants.GET_WELCOME_BONUS_LIST_FAILURE, error } }
}

function getLTArchiveRewardList(data) {
    return dispatch => {
        dispatch(request());
        dashboardService.getLTArchiveRewardList(data)
            .then(
                dashboards => {
                    // console.log("I AM IN ACTION________getLTArchiveRewardList::", dashboards);
                    dispatch(success(dashboards))
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_LTA_REWARD_LIST_REQUEST } }
    function success(dashboards) { return { type: dashboardConstants.GET_LTA_REWARD_LIST_SUCCESS, dashboards } }
    function failure(error) { return { type: dashboardConstants.GET_LTA_REWARD_LIST_FAILURE, error } }
}

function getFastrackBonusList(data) {
    return dispatch => {
        dispatch(request());
        dashboardService.getFastrackBonusList(data)
            .then(
                dashboards => {
                    dispatch(success(dashboards))
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_FASTRACK_BONUS_LIST_REQUEST } }
    function success(dashboards) { return { type: dashboardConstants.GET_FASTRACK_BONUS_LIST_SUCCESS, dashboards } }
    function failure(error) { return { type: dashboardConstants.GET_FASTRACK_BONUS_LIST_FAILURE, error } }
}

function getUserPackagesTeam() {
    return dispatch => {
        dispatch(request());
        dashboardService.getUserPackagesTeam()
            .then(
                dashboards => {
                    dispatch(success(dashboards))
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_USER_PACKAGES_TEAM_REQUEST } }
    function success(dashboards) { return { type: dashboardConstants.GET_USER_PACKAGES_TEAM_SUCCESS, dashboards } }
    function failure(error) { return { type: dashboardConstants.GET_USER_PACKAGES_TEAM_FAILURE, error } }
}
function getUserPackagesTeamTotalCount() {
    return dispatch => {
        dispatch(request());
        dashboardService.getUserPackagesTeamTotalCount()
            .then(
                dashboards => {
                    dispatch(success(dashboards))
                    // console.log("99999999999999999999999999", dashboards)
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.USER_PACKAGES_TOTAL_TEAM_REQUEST } }
    function success(dashboards) { return { type: dashboardConstants.USER_PACKAGES_TOTAL_TEAM_SUCCESS, dashboards } }
    function failure(error) { return { type: dashboardConstants.USER_PACKAGES_TOTAL_TEAM_FAILURE, error } }
}



function adddashboard(data) {
    return dispatch => {

        dashboardService.adddashboard(data)
            .then(
                adddashboard => {
                    dispatch(success(adddashboard));
                    dispatch(alertActions.success('dashboard added !'));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function success(adddashboard) { return { type: dashboardConstants.SUB_ADMIN_ADD_SUCCESS, adddashboard } }
    function failure(error) { return { type: dashboardConstants.SUB_ADMIN_ADD_FAILURE, error } }
}
function deletedashboard(data) {

    return dispatch => {

        dashboardService.deletedashboardService(data)
            .then(
                deletedashboard => {
                    dispatch(success(deletedashboard));
                    dispatch(alertActions.success('dashboard deleted !'));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    //function request(user) { return { type: dashboardConstants.LOGIN_REQUEST, user } }
    function success(deletedashboard) { return { type: dashboardConstants.SUB_ADMIN_DELETE_SUCCESS, deletedashboard } }
    function failure(error) { return { type: dashboardConstants.SUB_ADMIN_DELETE_FAILURE, error } }
}
function getAllResourceList() {
    return dispatch => {
        // dispatch(request());
        dashboardService.getAllResourceList()
            .then(
                resources => {
                    dispatch(success(resources));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    // function request() { return { type: dashboardConstants.GETALL_REQUEST } }
    function success(resources) { return { type: dashboardConstants.GET_RESOUCE_SUCCESS, resources } }
    function failure(error) { return { type: dashboardConstants.GET_RESOUCE_FAILURE, error } }
}
function getAssignedResourceList(data) {
    return dispatch => {
        // dispatch(request());
        dashboardService.getAssignedResourceList(data)
            .then(
                assignedresources => {
                    dispatch(success(assignedresources));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    // function request() { return { type: dashboardConstants.GETALL_REQUEST } }
    function success(assignedresources) { return { type: dashboardConstants.GET_ASSIGNED_RESOUCE_SUCCESS, assignedresources } }
    function failure(error) { return { type: dashboardConstants.GET_ASSIGNED_RESOUCE_FAILURE, error } }
}
function saveAssignResourcedashboard(data) {
    return dispatch => {

        dashboardService.saveAssignResourcedashboard(data)
            .then(
                saveassignedresources => {
                    dispatch(success(saveassignedresources));
                    dispatch(alertActions.success(saveassignedresources.updateResource.msg));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    // function request() { return { type: dashboardConstants.GETALL_REQUEST } }
    function success(saveassignedresources) { return { type: dashboardConstants.SAVE_ASSIGNED_RESOUCE_SUCCESS, saveassignedresources } }
    function failure(error) { return { type: dashboardConstants.SAVE_ASSIGNED_RESOUCE_FAILURE, error } }
}
function udpateStatus(data) {
    return dispatch => {
        dispatch(request());
        dashboardService.udpateStatus(data)
            .then(
                updatestatusdashboard => {
                    dispatch(success(updatestatusdashboard));
                    dispatch(alertActions.success('Status updated successfully'));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: dashboardConstants.UPDATE_STATUS_REQUEST, user } }
    function success(adddashboard) { return { type: dashboardConstants.UPDATE_STATUS_SUCCESS, adddashboard } }
    function failure(error) { return { type: dashboardConstants.UPDATE_STATUS_FAILURE, error } }
}

function resetDashboard() {
    return dispatch => {
        dispatch(success());
    };
    function success() { return { type: dashboardConstants.RESET_DASHBOARD_REDUX } }
}
