import React, { Component } from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
// import LoadingOverlay from 'react-loading-overlay';
import ReactPaginate from 'react-paginate';
import "jspdf-autotable";
import { isMobile } from "react-device-detect";
import { MdClose, MdOutlineChevronLeft } from "react-icons/md";
import { IoSendSharp } from "react-icons/io5";

class Support extends Component {
  constructor(props) {
    super(props);
    this.ticketSubmit = this.ticketSubmit.bind(this);
    this.inputChange = this.inputChange.bind(this);
    this.state = {
      offset: 0,
      size: 5,
      page: 1,
      errorsticket: {},
      fieldsticket: {},
      ticketTitle: "",
      fieldsAddUser: {},
      fieldTicketId: null,
      dropdownVisible: false,
      isResolved: null,
      selectedValue: "",
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.users.isTicketCreated) {
      console.log('nextProps.users.isTicketCreated', nextProps.users.isTicketCreated);
      return {
        ...nextProps,
        fieldsticket: {},
        errorsticket: {},
      }
    } else {
      return {
        ...nextProps
      }
    }
  }

  componentDidMount() {
    let temp = {
      "keyWord": "",
      "pageNo": this.state.page,
      "size": "5"
    }
    this.props.dispatch(userActions.getTicketList(temp));
    window.scrollTo(0, 0)
  }

  inputChange(e) {
    e.preventDefault();
    let { name, value } = e.target;
    this.setState({ [name]: value });
    let fieldsticket = this.state.fieldsticket;
    let errorsticket = this.state.errorsticket;
    fieldsticket[name] = value;
    errorsticket[name] = "";
    console.log('fieldsticket___________', fieldsticket);
    this.setState({ fieldsticket, errorsticket });
  }

  ticketSubmit = async (e) => {
    e.preventDefault();
    if (this.handleValidationTicket()) {
      let { msg } = this.state.fieldsticket;

      let temp = {
        "keyWord": "",
        "pageNo": "1",
        "size": "5"
      }

      try {
        await this.props.dispatch(
          userActions.createTicket({
            msg: msg,
          }, temp, this.props)
        );


        this.setState({ isTicketSubmitted: true });
        this.setState({ fieldsticket: {} })

        setTimeout(() => {
          this.setState({ isTicketSubmitted: false });
        }, 4000);
      } catch (error) {

        console.error("Ticket submission error:", error);
      }
    }
  };



  resetForm = (e) => {
    e.preventDefault();
    this.setState({
      fieldsticket: {},
      errorsticket: {},
    })
    this.hideErrorcomment();
  }

  handleValidationTicket = () => {
    let fieldsticket = this.state.fieldsticket;
    let errorsticket = {};
    let formIsValid = true;


    //msg
    if (!fieldsticket["msg"]) {
      formIsValid = false;
      errorsticket["msg"] = "The message is required";
    }
    if (fieldsticket["msg"] && fieldsticket["msg"].length < 20) {
      formIsValid = false;
      errorsticket["msg"] = "The message must be at least 20 characters ";
    }

    this.setState({ errorsticket: errorsticket });
    return formIsValid;
  }

  onClickMenu = (url) => {
    this.props.history.push(url)
  }


  handlePageClick = (data) => {
    let offset = Math.ceil(data.selected * this.state.size);
    this.setState({ offset: offset, page: data.selected });
    let datatemp = {
      "keyWord": this.state.keyWord,
      "pageNo": data.selected + 1,
      "size": this.state.size
    }
    this.props.dispatch(userActions.getTicketList(datatemp));
  }


  validattionReply = () => {
    let fieldsticket = this.state.fieldsticket;
    let errorsticket = {};
    let formIsValid = true;

    if (!fieldsticket["msg"] || !fieldsticket["msg"] === "" || !fieldsticket["msg"].trim() || fieldsticket["msg"].trim().length === 0) {
      formIsValid = false;
      errorsticket["msg"] = "The message is required";
    }

    this.setState({ errorsticket: errorsticket });
    return formIsValid;
  }

  handleRes = () => {
    if (this.validattionReply()) {
      let datatemp = {
        ticketId: this.state.fieldTicketId,
        msg: this.state.fieldsticket.msg
      }

      let temp = {
        "keyWord": "",
        "pageNo": this.state.page,
        "size": "5"
      }


      this.props.dispatch(userActions.replyByUserForTicket(datatemp, temp,))

      this.setState({ fieldsticket: {} })
    }
  }
  handleRess = () => {
    // if (this.validattionReply()) {
    let datatemp = {
      ticketId: this.state.fieldTicketId,
      msg: this.state.fieldsticket.msgs
    }

    let temp = {
      "keyWord": "",
      "pageNo": this.state.page,
      "size": "5"
    }

    if (this.state.fieldsticket.msgs && this.state.fieldsticket.msgs !== "") {


      this.props.dispatch(userActions.replyByUserForTicket(datatemp, temp,))

      this.setState({ fieldsticket: {} })
    }
    // }
  }

  TicketListById = (data) => {
    let temp = {
      "keyWord": "",
      "pageNo": this.state.page,
      "size": "5"
    }

    this.setState({ fieldTicketId: data.id })
    let resDataLast = {
      ticketId: data.id,
    }
    this.props.dispatch(userActions.getMsgListByTicketId(resDataLast, temp));
  }


  onClose = (data) => {
    this.setState({ fieldTicketId: null })
  }
  updateTicketStatus = (data) => {
    this.setState({ isResolved: data.isResolve })
    this.setState({ ticketTitle: data?.title || "" })
    let temp = {
      "keyWord": "",
      "pageNo": this.state.page,
      "size": "5"
    }


    this.setState({ fieldTicketId: data.id })

    let resDataLast = {
      ticketId: data.id,
    }
    this.props.dispatch(userActions.getMsgListByTicketId(resDataLast, temp))
  }


  changeSelectedValue = (e) => {
    this.setState({ selectedValue: e.target.value })
  }


  render() {

    let { users } = this.props;
    let { ticketList, ticketTotal, chatList } = users;
    const { isTicketSubmitted } = this.state;

    console.log("chatList", chatList)

    return (
      <>
        <main className="overflow-hidden overflow-y-auto" tabIndex={0}>
          <div className="mx-auto w-11/12 py-10">
            <div className='bg-[#F6F0FF] px-2 py-4 space-y-6'>
              <div className="flex items-center justify-between p-6 py-2">
                <div className="rounded-full p-1 bg-[#fff] shadow-lg" onClick={() => this.onClickMenu('/app/dashboard')}>
                  <MdOutlineChevronLeft className='text-[#4E2C7A]' size={30} />
                </div>
                <div className="">
                  <h1>Get In Touch</h1>
                </div>
                <div>
                </div>
              </div>
            </div>
            <section className="grid grid-cols-12 gap-5 py-6">

              <div className="flex-col col-span-12 px-5 py-5 bg-transparent border border-[#4E2C7A]  rounded-[14px] shadow-lg 2xl:col-span-4 xl:col-span-5 lg:col-span-6 2xl:py-7 2xl:px-10">
                {isTicketSubmitted && (
                  <h2 className='py-2 text-sm text-green-500'>
                    Thank you for contacting us.
                  </h2>
                )}
                <h3 className="md:text-2xl text-lg leading-6 font-semibold text-[#4E2C7A] border-b pb-3 border-[#4E2C7A]">We are here to support you</h3>

                <form autoComplete="off" onSubmit={this.ticketSubmit} className="mt-4 space-y-3">
                  <div className="relative flex flex-col space-y-1 rounded-md">
                    <label className="block text-[18px] font-[400] text-[#4E2C7A]">Message:</label>
                    <textarea rows={6} className={`block w-full px-4 py-3 leading-tight text-[#000] text-base font-normal bg-transparent border border-gray-400 rounded-md appearance-none focus:outline-none ${!this.state.errorsticket["msg"] ? "'border-opacity-20 " : "order-opacity-100  bg-gray-100"}`}
                      name="msg"
                      value={this.state.fieldsticket["msg"] ? this.state.fieldsticket["msg"] : ''}
                      onChange={this.inputChange} placeholder="Write us." type="text" />
                    {this.state.errorsticket["msg"] ?
                      <div className="mt-1 font-serif text-red-500 invalid-feedback">
                        {this.state.errorsticket["msg"]}
                      </div>
                      : null}
                  </div>
                  <div className="block w-full rounded-md">
                    <button className="flex justify-center w-full px-3 py-3 mx-auto text-sm text-white uppercase transition duration-150 ease-in-out  rounded-md  lg:w-4/6 font-medium all-btn-color focus:outline-none" type="submit" data-config-id="01_primary-action">Submit</button>
                  </div>

                </form>
              </div>

              <div className="flex-col col-span-12 mb-8 bg-transparent border border-[#4E2C7A]  rounded-[14px] shadow-lg 2xl:col-span-8 lg:mb-0 xl:col-span-7 lg:col-span-6 ">
                <div className="flex flex-wrap justify-between w-full p-5">
                  <h3 className="text-lg font-semibold leading-6 text-center text-[#4E2C7A] md:text-2xl md:leading-9">Previous concern</h3>
                </div>
                <div className="pb-2 overflow-hidden">
                  <div className="max-w-full overflow-x-auto ">
                    <div className="inline-block min-w-full ">
                      <div className="overflow-x-auto">

                        <table className="min-w-full border-0 divide-y divide-gray-300">
                          <thead className="all-btn-color">
                            <tr className="">
                              <th scope="col" className="py-2 text-sm font-medium tracking-wider text-left text-white capitalize whitespace-nowrap" style={{ width: "5%" }}>#</th>
                              <th scope="col" className="py-2 text-sm font-medium tracking-wider text-left text-white capitalize whitespace-nowrap" style={{ width: "30%" }}>Ticket No</th>
                              <th scope="col" className=" py-2 text-sm font-medium tracking-wider text-left text-white capitalize whitespace-nowrap" style={{ width: "40%" }}>Message</th>
                              <th scope="col" className=" py-2 text-sm font-medium tracking-wider text-left text-white capitalize whitespace-nowrap" style={{ width: "15%" }}>Status</th>
                              <th scope="col" className=" py-2 text-sm font-medium tracking-wider text-left text-white capitalize whitespace-nowrap" style={{ width: "10%" }}>Action</th>
                            </tr>
                          </thead>

                          <tbody className='divide-y-8 divide-white'>
                            {
                              ticketList && ticketList.length > 0 ?
                                ticketList.map((element, index) => (
                                  <React.Fragment key={element.id}>
                                    <tr className="bg-white bg-opacity-5">
                                      <td className="py-1 border-b whitespace-nowrap text-[14px] text-[#837BB0] bg-[#F6F0FF]" style={{ width: "5px" }}>{this.state.offset + index + 1}</td>
                                      <td className="py-1 border-b whitespace-nowrap text-[14px] text-[#837BB0] bg-[#F6F0FF]" style={{ width: "30px" }}>{element && element.ticketNo ? element.ticketNo : '-'}</td>
                                      <td className="py-1 border-b whitespace-nowrap text-[14px] text-[#837BB0] bg-[#F6F0FF]" title={(element && element.msg) ? element.msg : '-'} style={{ width: "40px", overflow: "hidden", textOverflow: "ellipsis" }}>{(element && element.msg) ? (element.msg.length > 10 ? element.msg.substring(0, 30) + '...' : element.msg) : '-'}</td>

                                      {element?.isResolve ? < td className=" py-1 border-b whitespace-nowrap text-[14px] bg-[#F6F0FF] text-[#007F00]" style={{ width: "15px" }}>Approved</td> : < td className=" py-1 border-b whitespace-nowrap text-[14px] bg-[#F6F0FF] text-yellow-400" style={{ width: "15px" }}>Pending</td>}


                                      <td className=" py-1 border-b whitespace-nowrap text-[14px] text-[#837BB0] bg-[#F6F0FF]" style={{ width: "10px" }}>
                                        <div className=' relative'>
                                          <button class="text-white all-btn-color font-bold py-1 px-2 mr-1 border rounded" onClick={() => this.updateTicketStatus(element)}>Reply</button>

                                          {Boolean(element.count) && <div className='-top-1 right-3 p-1 text-xs bg-red-600 text-white rounded-full flex justify-center items-center absolute'>{element.count}</div>}
                                        </div>
                                      </td>
                                    </tr>
                                  </React.Fragment>))
                                : (
                                  <tr>
                                    <td colSpan="6" className="text-center text-[#837BB0]">No Record Found</td>
                                  </tr>)
                            }
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  {
                    isMobile ?
                      <nav className="relative z-0 flex justify-end mt-5 w-76">
                        {
                          ticketTotal && ticketTotal > 10 ?
                            <ReactPaginate
                              previousLabel={'Prev'}
                              nextLabel={'Next'}
                              breakLabel={'...'}
                              breakClassName={'break-me'}
                              pageCount={ticketTotal / this.state.size}
                              marginPagesDisplayed={1}
                              pageRangeDisplayed={-1}
                              onPageChange={this.handlePageClick}
                              containerClassName={'pagination'}
                              pageClassName={'page-cls'}
                              activeClassName={'active'}
                            />
                            : null}
                      </nav> : <nav className="relative z-0 flex justify-end mt-5 w-76">
                        {
                          ticketTotal && ticketTotal > 10 ?
                            <ReactPaginate
                              previousLabel={'Previous'}
                              nextLabel={'Next'}
                              breakLabel={'...'}
                              breakClassName={'break-me'}
                              pageCount={ticketTotal / this.state.size}
                              marginPagesDisplayed={0}
                              pageRangeDisplayed={1}
                              onPageChange={this.handlePageClick}
                              containerClassName={'pagination'}
                              pageClassName={'page-cls'}
                              activeClassName={'active'}
                            />
                            : null}
                      </nav>
                  }

                </div>
              </div>
            </section>

          </div >

          {

            this.state.fieldTicketId ?
              <>
                <div className="fixed inset-0 z-50 flex items-center justify-center w-full overflow-hidden main-modal h-100 animated fadeIn faster bg-black/50">
                  <div className="lg:w-[30%] w-full bg-white z-50 mx-auto rounded-lg shadow-lg">
                    <div className="flex items-center justify-between p-2 border-b">
                      <div className="flex items-center">
                        <div className="pl-2">
                          <div className="font-semibold capitalize text-lg text-[#0E1F51]">{this.state.ticketTitle || ""}</div>
                        </div>
                      </div>

                      <div>
                        <button className="inline-flex p-2 rounded-full hover:bg-indigo-50" type="button">
                          <MdClose className='w-5 h-5' onClick={() => this.onClose()} />
                        </button>
                      </div>
                    </div>
                    <div className="flex-1 px-4 py-4 overflow-y-auto custom-scroll h-96">
                      {chatList && chatList && chatList.length > 0 ?
                        chatList.map((element, index) => {

                          return (
                            <>
                              {element && element.isAdmin && element.isAdmin === true ?
                                <div className="flex items-center mb-4">
                                  <div className="relative flex-1 p-2 mb-2 text-white bg-indigo-400 rounded-lg">
                                    <div className=' flex flex-col'>
                                      <div className='flex flex-col'>
                                        {element && element?.msg ? element
                                          ?.msg.split('\n').map((line, index) => (
                                            <React.Fragment key={index}>
                                              {line}
                                              <br />
                                            </React.Fragment>
                                          )) : null}
                                      </div>
                                      <div className=' whitespace-nowrap text-[0.59rem]  flex justify-end items-end'>{element && element?.ticketId.createdAt && new Date(element?.ticketId.createdAt).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', day: '2-digit', month: '2-digit', year: 'numeric' })}</div>
                                    </div>
                                    <div className="absolute left-0 w-2 h-2 transform rotate-45 -translate-x-1/2 bg-indigo-400 top-1/2" />
                                  </div>
                                </div>
                                :


                                < div className="flex flex-row-reverse items-center mb-4" >
                                  <div className="flex flex-col items-center flex-none ml-4 space-y-1">
                                    <span className="block text-xs capitalize hover:underline">{element && element.userId && element.userId.fullName ? element.userId.fullName : '-'}</span>
                                  </div>
                                  <div className="relative flex-1 p-2 mb-2 text-gray-800 bg-indigo-100 rounded-lg">
                                    <div className=' flex flex-col'>
                                      <div className='flex justify-start items-start'>
                                        {element && element?.msg ? element?.msg.split('\n').map((line, index) => (
                                          <React.Fragment key={index}>
                                            {line}
                                            <br />
                                          </React.Fragment>
                                        )) : null}
                                      </div>
                                      <div className=' whitespace-nowrap text-[0.59rem]  flex justify-end items-end'>{element && element?.createdAt && new Date(element?.createdAt).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', month: '2-digit', day: '2-digit', year: 'numeric' })}</div>
                                    </div>
                                    <div className="absolute right-0 w-2 h-2 transform rotate-45 translate-x-1/2 bg-indigo-100 top-1/2" />
                                  </div>
                                </div>

                              }



                            </>
                          )
                        }

                        )
                        : null
                      }


                    </div>
                    <div className="flex items-center p-2 border-t">
                      <div className="w-full mx-2">
                        {this.state.isResolved ?
                          <>

                            <textarea disabled rows={1} className={`  whitespace-break-spaces w-full border border-gray-200 rounded-full outline-none px-4 py-2.5`}
                              name="msg"
                              value={this.state.fieldsticket["msg"] ? this.state.fieldsticket["msg"] : ''}
                              onChange={this.inputChange} placeholder="message" type="text" />
                            {this.state.errorsticket["msg"] ?
                              <div className="mt-1 font-serif text-red-500 invalid-feedback">
                                {this.state.errorsticket["msg"]}
                              </div>
                              : null}
                          </>
                          :
                          <>
                            <textarea rows={1} className={`  whitespace-break-spaces w-full border border-gray-200 rounded-full outline-none px-4 py-2.5`}
                              name="msgs"
                              value={this.state.fieldsticket["msgs"] ? this.state.fieldsticket["msg"] : ''}
                              onChange={this.inputChange} placeholder="message" type="text" />
                            {this.state.errorsticket["msgs"] ?
                              <div className="mt-1 font-serif text-red-500 invalid-feedback">
                                {this.state.errorsticket["msgs"]}
                              </div>
                              : null}

                          </>
                        }
                      </div>
                      <div>
                        {
                          this.state.isResolved ?
                            <button className="inline-flex p-2 rounded-full all-btn-color hover:bg-indigo-500" >
                              <IoSendSharp className="w-5 h-5 text-white" />
                            </button>
                            :
                            <button className="inline-flex p-2 rounded-full all-btn-color hover:bg-indigo-500" onClick={() => { this.handleRess() }} >
                              <IoSendSharp className="w-5 h-5 text-white" />
                            </button>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </>
              : null
          }

        </main >


      </>
    );
  }
}
function mapStateToProps(state) {
  const { users, authentication } = state;
  return {
    users,
    authentication
  };
}
export default connect(mapStateToProps)(Support);
