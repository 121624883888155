import React from "react";
import { Route, Switch, withRouter, } from "react-router-dom";
import Logout from "../../pages/Logout/Logout";
import Header from "../Header";
import Dashboard from "../../pages/dashboard";
import Profile from "../../pages/profile";
import service from "../../pages/profile/service";
import Transactions from "../../pages/transactions";
import Support from '../../pages/support'; 
import Sidebar from "../Sidebar";
import changePassword from "../../pages/changePassword/changePassword";
import Send from "../../pages/Send/Send";
import Report from "../../pages/Report/Report";
import Query from "../../pages/Query/Query";
import Assessment from "../../pages/Assessment/Assessment";
import Sceerining from "../../pages/Sceerining/Sceerining";
import PreSceerining from "../../pages/PreviousSceerining/Sceerining";
import Introduction from "../../pages/Introduction/Introduction";
import AddContract from "../../pages/AddContract/AddContract";
import PreAssessment from "../../pages/preAssesmentQuestion/Assessment";
import Donation from "../../pages/profile/Donation";
import EditStudent from "../../pages/EditStudent/EditStudent";


function Layout(props) {

  const currentPath = props.location.pathname.split('/');
  const dashboard = currentPath[2];

  return (
    <div >
      <>
        <div className="overflow-hidden bg-[#fff] ">
          <div className="bg-[#fff] ">
            <div className="flex flex-col justify-center h-screen">
              <div className="flex h-screen overflow-hidden" >
                <Sidebar />
                <div className="flex flex-col flex-1 w-full overflow-hidden overflow-y-auto">
                  <Header history={props.history} currentPath={dashboard} name="" />
                  <Switch>
                    <Route path="/app/Acknowledgements" component={withRouter(changePassword)} />
                    <Route path="/app/dashboard/:walletName?/:address?" component={withRouter(Dashboard)} />
                    <Route path="/app/OrkidService" component={withRouter(Profile)} />
                    <Route path="/app/Send/" component={withRouter(Send)} />
                    <Route path="/app/report/:id/:scoreId" component={withRouter(Report)} />
                    <Route path="/app/Query/" component={withRouter(Query)} />
                    <Route path="/app/assessment/:id" component={withRouter(Assessment)} />
                    <Route path="/app/Introduction/:id" component={withRouter(Introduction)} />
                    <Route path="/app/screening/:id" component={withRouter(Sceerining)} />
                    <Route path="/app/previous-screening/:id" component={withRouter(PreSceerining)} />
                    <Route path="/app/support" component={withRouter(Support)} />
                    <Route path="/app/AboutFirstScreen" component={withRouter(Transactions)} />
                    <Route path="/app/AddContract" component={withRouter(AddContract)} />
                    <Route path="/app/editStudent/:id" component={withRouter(EditStudent)} />
                    <Route path="/app/logout" component={withRouter(Logout)} />
                    <Route path="/app/service/:id" component={withRouter(service)} />
                    <Route path= "/app/pre-assessment/:id" component={withRouter(PreAssessment)} />
                    <Route path= "/app/donation" component={withRouter(Donation)} />

                  </Switch>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
}

export default withRouter(Layout);


