import React, { Component } from "react";
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import { AiOutlineMail } from 'react-icons/ai';
import { MdAddCall } from "react-icons/md";
import logo from "./logo.png"
import firstScreen from "./firstScreen.png"
import Timmer from "../../components/Timmer/Timmer";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

class Login extends Component {
  constructor(props) {
    super(props);
    this.props.dispatch(userActions.logout());
    this.loginSubmit = this.loginSubmit.bind(this);
    this.inputChange = this.inputChange.bind(this);
    this.state = {
      fieldslogin: {},
      errorslogin: {},
      otpSentLogin: false,
      showPassword: false,
      rememberMeChecked: false,
      otpSentRegister: false,
      inputChangeField: true,
      phone: ""
    }
    this.togglePasswordVisibility = this.togglePasswordVisibility.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.otpSentLogin) {
      return {
        ...nextProps,
        otpSentLogin: nextProps.otpSentLogin ? nextProps.otpSentLogin : false
      }
    } else {
      return {
        ...nextProps
      }
    }
  }

  inputChange = (e) => {
    if (e) {
      e.preventDefault();
      const { name, value } = e.target;

      // Handle specific input validations or conditions
      if (name === "mobNo" && value.length > 10) {
        return; // Do not update state if 'mobNo' exceeds 10 characters
      }

      // Update state for the specific input
      this.setState(prevState => ({
        fieldslogin: {
          ...prevState.fieldslogin,
          [name]: value
        },
        errorslogin: {
          ...prevState.errorslogin,
          [name]: ""
        }
      }));
    }
  };


  loginSubmit(e) {
    if (e) {
      e.preventDefault();
    }
    console.log(this.state.fieldslogin)
    console.log(this.state.phone)
    if (this.handleValidationLogin()) {
      let { mobNo, email } = this.state.fieldslogin;
      if (email) {
        this.props.dispatch(
          userActions.login({ email: email }, this.props)
        );
      } else if (mobNo) {
        console.log(mobNo, email)
        this.props.dispatch(
          userActions.login({ mobNo: mobNo }, this.props)
        );
      }
    }
  }

  otpSubmit = (e) => {
    const { registeruser } = this.props;
    console.log('registeruser', registeruser)
    let token = registeruser && registeruser.userToken ? registeruser.userToken : null;
    this.props.dispatch(userActions.loginValidateOtp({ token: token, otp: Number(this.state.fieldslogin["OTP"]) }, this.props));
  }

  resetForm = (e) => {
    e.preventDefault();
    this.setState({
      fieldslogin: {},
      errorslogin: {},
    })
    this.hideErrorMessage();
  }

  handleValidationLogin = () => {
    let fieldslogin = this.state.fieldslogin;
    let errorslogin = {};
    let formIsValid = true;
    let phone = this.state.phone;

    // Email Validation
    if (this.state.inputChangeField && !fieldslogin["mobNo"]) {
      formIsValid = false;
      errorslogin["mobNo"] = "Please enter a valid mobile number.";
    } 

    // Mobile Number Validation
    if (!this.state.inputChangeField && !fieldslogin["email"]) {
      formIsValid = false;
      errorslogin["email"] = "Please enter a valid email.";
    } else if (fieldslogin["email"] && !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(fieldslogin["email"])) {
      formIsValid = false;
      errorslogin["email"] = "Email is not valid.";
    }

    // OTP Validation (if OTP is being entered)
    if (this.state.otpSentRegister && (!fieldslogin["OTP"] || !/^\d{6}$/.test(fieldslogin["OTP"]))) {
      formIsValid = false;
      errorslogin["OTP"] = "OTP must be 6 digits.";
    }

    this.setState({ errorslogin: errorslogin });
    return formIsValid;
  }

  componentDidMount() {
    const rememberMeChecked = localStorage.getItem('rememberMeChecked') === 'true';
    this.setState({ rememberMeChecked });
  }

  onClickMenu = (url) => {
    this.props.history.push(url);
  }

  togglePasswordVisibility() {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }));
  }

  handleCheckboxChange = (event) => {
    const { checked } = event.target;
    this.setState({ rememberMeChecked: checked });
    localStorage.setItem('rememberMeChecked', checked);
  };

  handleStateChange = () => {
    this.setState({ inputChangeField: !this.state.inputChangeField });
  };
  handlePhoneChange = (value) => {
    this.setState({ phone: value });
    this.setState({ fieldslogin: {...this.state.fieldslogin, "mobNo": value } });
  };

   handleClick = () => {
    this.loginSubmit ()
  
  };

  render() {
    let { loading } = this.props;
    const { otpSentRegister } = this.props;

    return (
      <>
        <section className="flex items-center w-full">
          <div className="bg-[#fff] w-full relative lg:block hidden">
            <img className="absolute top-0 left-0" src="/img/Vector.png" alt="" />
            <div className="flex flex-col justify-center items-center h-screen">
              <div>
                <img src="/img/logo-login.png" alt="" />
              </div>
              <div className=" py-6">
                <h1 className="text-[#4E2C7A] text-[60px] font-[700] text-center">First Screen By Orkids</h1>
              </div>
            </div>
            <img className="absolute bottom-0 right-0" src="/img/Vector-1.png" alt="" />
          </div>
          <div className="lg:bg-[#F6F0FF] bg-white w-full">
            <div className="flex-col justify-center  flex w-5/6 mx-auto space-y-4 xl:w-3/6 h-screen">
              <div className=" block">
                <div>
                  <img src={logo} alt="" className="w-[100px] mx-auto" />
                </div>
                <div>
                  <img src={firstScreen} alt="" className="w-[160px] mx-auto" />
                </div>

              </div>
              <div className="text-center">
                <h1 className="text-[45px] font-[700] text-[#4E2C7A]">Log in!</h1>
              </div>
              <div className="space-y-2">
                {this.state.inputChangeField ?
                  <>
                    <label className="text-sm font-medium text-[#4E2C7A]">Mobile Number<span className="text-red-700">*</span></label>
                    {/* <div className="relative flex items-center">
                      <MdAddCall className="absolute right-3 text-gray-400" />
                      <input
                        disabled={otpSentRegister ? true : false}
                        className={`w-full border px-4 py-2.5 rounded-md focus:outline-none text-base font-normal placeholder-[#000] placeholder:text-[#c1bfe9] bg-transparent text-[#000] ${!this.state.errorslogin["mobNo"] ? "border-opacity-100 border placeholder-[#000] font-base" : "border-opacity-100 border-none ring-2 ring-red-300"}`}
                        value={this.state.fieldslogin&&this.state.fieldslogin["mobNo"]?this.state.fieldslogin["mobNo"]:""}
                        id="mobNo"
                        name="mobNo"
                        placeholder="Enter your Mobile Number"
                        type="number"
                        maxLength={10}
                        onChange={this.inputChange}
      
                      />
                    </div> */}
                    <div className="relative flex items-center">
                      <PhoneInput
                         country={'in'} 
                        value={this.state.phone}
                        onChange={this.handlePhoneChange}
                        disabled={otpSentRegister}
                        inputStyle={{
                          width: '100%',
                          border: this.state.errorslogin["mobNo"] ? 'none' : '1px solid #ccc',
                          borderRadius: '4px',
                          padding: '10px',
                          paddingLeft: '48px', // Adjust padding for the flag dropdown
                          outline: 'none',
                          fontSize: '16px',
                          fontFamily: 'inherit',
                          backgroundColor: 'transparent',
                          color: '#000',
                        }}
                        buttonStyle={{
                          borderRadius: '4px 0 0 4px',
                          borderRight: '1px solid #ccc',
                        }}
                        containerStyle={{
                          width: '100%',
                        }}
                        placeholder="Enter your Mobile Number"
                      />
                      <MdAddCall className="absolute right-3 text-gray-400" />
                    </div>
                    {this.state.errorslogin["mobNo"] ? <div className="mt-1 font-serif text-red-500 invalid-feedback">{this.state.errorslogin["mobNo"]}</div> : null}
                    <p className="text-right cursor-pointer" onClick={() => this.handleStateChange()} >Use Email Address</p>
                  </> :
                  <>
                    <label className="text-sm font-medium text-[#4E2C7A]">Email Address<span className="text-red-700">*</span></label>
                    <div className="relative flex items-center">
                      <AiOutlineMail className="absolute right-3 text-gray-400" />
                      <input
                        disabled={otpSentRegister ? true : false}
                        className={`w-full border px-4 py-2.5 rounded-md focus:outline-none text-base font-normal placeholder-[#000] placeholder:text-[#c1bfe9] bg-transparent text-[#000] ${!this.state.errorslogin["email"] ? "border-opacity-100 border placeholder-[#000] font-base" : "border-opacity-100 border-none ring-2 ring-red-300"}`}
                        id="email"
                        name="email"
                        placeholder="example@example.com"
                        type="email"
                        onChange={this.inputChange}
                        onKeyPress={(e) => { if (e.key === "Enter") { this.loginSubmit(); } }}
                      />
                    </div>
                    {this.state.errorslogin["email"] ? <div className="mt-1 font-serif text-red-500 invalid-feedback">{this.state.errorslogin["email"]}</div> : null}
                    <p className="text-right cursor-pointer" onClick={() => this.handleStateChange()}>Use Mobile Number</p>
                  </>}
              </div>
              {
                otpSentRegister ?
                  <div className="">
                    <div className="">
                      <label className="text-sm font-medium text-[#4E2C7A]">Access Code</label>
                    </div>
                    <input className={`w-full border px-4 py-2.5 rounded-md focus:outline-none text-base font-normal text-black placeholder-black placeholder:text-[#c1bfe9] bg-transparent  ${!this.state.errorslogin["OTP"] ? "'border-opacity-20 border-opacity-100 border placeholder-black font-base " : "border-opacity-100 border-none ring-2 ring-red-300"}`} id="OTP" name="OTP" maxLength={6}
                      value={this.state.fieldslogin["OTP"] ? this.state.fieldslogin["OTP"] : ''}
                      onChange={this.inputChange} placeholder="Enter access code" type="text"
                    />
                    {this.state.errorslogin["OTP"] ?
                      <div className="w-5/6 invalid-feedback text-red -500">
                        {this.state.errorslogin["OTP"]}
                      </div>
                      : null}
                  </div>
                  : null
              }

              {otpSentRegister && <div className=" flex justify-center items-center"> Don't receive the OTP ? <Timmer handleClick={this.handleClick} /></div>}
              {otpSentRegister ?
                <div className="">
                  <button className="w-full py-3 mx-auto text-white transition duration-150 ease-in-out rounded-[8px] cursor-pointer all-btn-color lg:font-bold focus:outline-none focus:underline" type="button" onClick={this.otpSubmit} >Submit</button>
                </div>
                :
                <div className="">
                  <button className="w-full py-3 mx-auto text-white transition duration-150 ease-in-out rounded-[8px] cursor-pointer all-btn-color lg:font-bold focus:outline-none focus:underline uppercase" type="submit" onClick={this.loginSubmit}>Get Otp</button>
                </div>
              }


              {!otpSentRegister && <div>
                <p className="text-center text-[#c1bfe9] ">We will send an OTP to your <br /> <b>register mobile number</b></p>
              </div>}
            </div>
          </div>
        </section>
      </>
    )
  }
}

function mapStateToProps(state) {
  const { loggingIn, user, otpSentLogin, registeruser, loading, otpSentRegister } = state.authentication;
  const { users, authentication } = state;
  return {
    loggingIn,
    otpSentLogin,
    otpSentRegister,
    user,
    users,
    registeruser,
    loading,
    authentication
  };
}

export default connect(mapStateToProps)(Login);
