import React, { Component } from 'react';
import { MdOutlineChevronLeft } from 'react-icons/md'
class Query extends Component {
  constructor(props) {
    super(props);
    this.chartRef = React.createRef();
  }


  onClickMenu = (url) => {
    this.props.history.push(url);
  };

  render() {
    return (
      <section className='overflow-hidden overflow-y-auto'>
        <div className="lg:w-7/12 w-11/12 mx-auto py-10">
          <div className='bg-[#F6F0FF] px-2 py-4 space-y-6'>
            <div className="flex items-center justify-between p-6 py-2">
              <div className="rounded-full p-1 bg-[#fff] shadow-lg" onClick={() => this.onClickMenu('/app/dashboard')}>
                <MdOutlineChevronLeft className='text-[#4E2C7A]' size={30} />
              </div>
              <div className="">
                <h1>Query</h1>
              </div>
              <div>
              </div>
            </div>
          </div>
          <div className='py-10'>
            <h3 className="md:text-2xl text-center text-lg leading-6 font-semibold text-[#4E2C7A]">Do you know
              the child
              for 6 months or
              more?</h3>

            <div className='py-10 space-y-6'>
              <div className='flex justify-center items-center'>
                <button type="submit" class="text-white bg-[#4E2C7A] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-40  px-5 py-2.5 text-center dark:hover:bg-[#4E2C7A] ">Yes</button>
              </div>
              <div className='flex justify-center items-center'>
                <button type="submit" class="text-white bg-[#4E2C7A] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-40  px-5 py-2.5 text-center dark:hover:bg-[#4E2C7A] ">No</button>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Query;
