import React, { Component } from 'react';
import { MdOutlineChevronLeft } from 'react-icons/md';
import { FiChevronRight } from 'react-icons/fi';
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
// import LoadingOverlay from 'react-loading-overlay';

class Send extends Component {
  constructor(props) {
    super(props);
    this.state = {
      to_address: '',
      amount: '',
      otp: '',
      errors: {},
    };
  }

  componentDidMount() {
    this.props.dispatch(userActions.clearCache());
  }

  onSendCoin = () => {
    const { to_address, amount } = this.state;
    if (!to_address || !amount) {
      this.setState({
        errors: {
          to_address: to_address ? '' : 'Receiving address is required',
          amount: amount ? '' : 'Amount is required',
        },
      });
      return;
    }

    const data = {
      to_address,
      send_token_amount: amount,
    };

    console.log('Data to send:', data);
    this.props.dispatch(userActions.sendCoin(data, this.props));
  };

  validateSendCoinWithOtp = () => {
    const { otp } = this.state;
    if (!otp) {
      this.setState({
        errors: {
          otp: 'OTP is required',
        },
      });
      return;
    }

    const { users } = this.props;
    const { token } = users;
    const data = {
      token: token.token,
      otp,
    };

    console.log('Data to validate OTP:', data);
    this.props.dispatch(userActions.sendCoinOtpVerification(data, this.props));
  };

  onClickMenu = (url) => {
    this.props.history.push(url);
  };



  handleOpenNetwork = () => {
    this.props.history.push('/app/Sceerining')
  };

  render(ele) {
    const { users } = this.props;
    const { loading } = users;

    return (
      <>
        <div>
          {/* <LoadingOverlay active={loading} className="temp001" spinner text="Please wait..." /> */}
        </div>

        <div className="h-screen xl:py-16 py-6 overflow-y-auto item-center flex justify-center">
          <div className='lg:w-7/12 w-11/12 mx-auto py-10 overflow-hidden overflow-y-auto'>
            <div className='bg-[#F6F0FF] px-2 py-4 space-y-6'>
              <div className="flex items-center justify-between p-6 py-2">
                <div className="rounded-full bg-white p-1 shadow-lg" onClick={() => this.onClickMenu('/app/dashboard')}>
                  <MdOutlineChevronLeft className='text-[#4E2C7A]' size={30} />
                </div>
              </div>
            </div>
            <div className='flex justify-center items-start -mt-10'>
              <img src="/img/suresh-singh.png" alt="" className='rounded-full w-16 h-16' />
            </div>

            <div className='flex flex-col justify-center items-center py-4'>
              <h1>Vikram Rathore</h1>
              <p className='text-[#4E2C7A]'>Class 6</p>
            </div>
            <div className='flex justify-between items-center border-b border-[#D9D9D975] cursor-pointer bg-[#F6F0FF] p-4 rounded-full' onClick={() => this.handleOpenNetwork(ele)}>
              <div className='flex flex-col space-y-1'>
                <div className='flex items-center space-x-2'>
                  <div className=' text-white rounded-full w-14 h-14 flex justify-center items-center'>
                    <img src="/img/search-icon.png" alt="" />
                  </div>
                  <div>
                    <p className='text-[#000] text-[16px] font-[600]'>Start New Screening</p>
                  </div>
                </div>
              </div>
              <div>
                <FiChevronRight color='#4E2C7A' size={30} />
              </div>
            </div>
            <div className='py-4'>
              <p className='text-[#4E2C7A]'>Previous Screening Results</p>
            </div>

            <div className='space-y-4'>
              <div className='flex justify-between items-center border border-[#8F57C7] cursor-pointer  p-4 rounded-full' >
                <div className='flex flex-col space-y-1'>
                  <div>
                    <p className='text-[#4E2C7A] text-[16px] font-[600]'>10-05-2024</p>
                  </div>
                </div>
                <div>
                  <p className='text-[#4E2C7A]'>Review</p>
                </div>
              </div>
              <div className='flex justify-between items-center border border-[#8F57C7] cursor-pointer  p-4 rounded-full' >
                <div className='flex flex-col space-y-1'>
                  <div>
                    <p className='text-[#4E2C7A] text-[16px] font-[600]'>6-05-2024</p>
                  </div>
                </div>
                <div>
                  <p className='text-[#4E2C7A]'>Review</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { users } = state;
  const { overview } = users || {};
  const { setting, user, wallet } = overview || {};
  return {
    users,
    setting,
    user,
    wallet,
  };
}

export default connect(mapStateToProps)(Send);
