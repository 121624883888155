// import React from "react";
// import { connect } from 'react-redux';
// import { withRouter } from "react-router-dom";
// import { confirmAlert } from 'react-confirm-alert';
// import 'react-confirm-alert/src/react-confirm-alert.css';
// import { MdOutlineInfo } from "react-icons/md";
// import { FaUserTie } from "react-icons/fa";
// import { TbMessages } from "react-icons/tb";
// import { FaAward } from "react-icons/fa6";
// import { FaMailBulk } from "react-icons/fa";
// import { FaPowerOff } from "react-icons/fa6";
// import Donate from "./Donate.png"



// function Sidebar({ location, history }) {

//   const [navbarOpen, setNavbarOpen] = React.useState(false);
//   const onClickMenu = (url) => {
//     history.push(url);
//     setTimeout(() => {
//       setNavbarOpen(!navbarOpen)
//     }, 150);
//   }

//   const logout = (url) => {
//     window.sessionStorage.removeItem('user');
//     history.push(url);
//     setTimeout(() => {
//       setNavbarOpen(!navbarOpen)
//     }, 150);
//   }

//   const logoutSubmit = () => {
//     confirmAlert({
//       title: 'Are you sure you want to logout?',
//       buttons: [
//         {
//           label: 'Logout',
//           onClick: () => logout('/login')
//         },
//         {
//           label: 'Cancel'
//         }
//       ],
//       overlayClassName: 'custom-overlay'
//     });
//   }

//   return (
//     <>

//       <button className={` ${navbarOpen === true ? 'hidden' : 'flex'} absolute top-0 left-0 px-5 items-center justify-center  text-[#4D207A] focus:outline-none focus:bg-gray-800 focus:text-gray-600 z-50 h-16 lg:hidden `} onClick={() => setNavbarOpen(!navbarOpen)}>
//         <svg className="w-6 h-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
//           <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h7">
//           </path>
//         </svg>
//       </button>
//       {navbarOpen ?
//         <div className={` ${navbarOpen === false ? 'hidden' : ''} lg:hidden  lg:flex-shrink-0 lg:static inset-0 z-50 fixed bg-opacity-40`} onClick={() => setNavbarOpen(!navbarOpen)}>
//           <div className="flex items-center justify-center h-8 w-8 rounded-full focus:outline-none focus:bg-gray-600 bg-[#1C2745] absolute left-[260px] lg:left-80 top-2 z-50" aria-label="Close sidebar">
//             <svg className="w-6 h-6 text-white z-50" stroke="currentColor" fill="none" viewBox="0 0 24 24">
//               <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12">
//               </path>
//             </svg>
//           </div>
//         </div>
//         : null}

//       {/* Main Navbar */}
//       <div className={` ${navbarOpen === false ? 'hidden' : 'flex'} fixed left-0 inset-y-0 lg:static lg:left-auto lg:inset-auto lg:flex lg:w-80 w-64 z-50 lg:m-10 m-4`}>

//         <div className="relative flex flex-col w-64 overflow-y-auto lg:w-80 bg-right-top bg-no-repeat">
//           <div className="flex flex-col flex-1 pt-1  bg-[#F6F0FF] rounded-[14px]">
//             <div onClick={() => onClickMenu('/app/dashboard')} className="sticky top-0 z-50 flex items-center  w-full py-2 px-4 border-b-2 border-[#A9A9AA]/20 cursor-pointer">
//               <img className="w-full" src="/img/Frame.png" alt="gcn.png" />
//             </div>
//             <nav className="flex-1 w-full px-5 py-4 space-y-4">
//               <div onClick={() => onClickMenu('/app/dashboard')} className={`flex items-center w-full h-12 px-3 mt-2 rounded-lg cursor-pointer   hover:all-btn-color transition-all duration-300 ${location.pathname === "/app/dashboard" ? " text-white	 bg-[#4E2C7A] rounded-lg" : " text-[#898CAF]"}`}>
//                 <FaUserTie className="w-7 h-7" />

//                 <span className="ml-4 text-lg font-medium">Dashboard</span>
//               </div>
//               <div onClick={() => onClickMenu('/app/AboutFirstScreen')} className={`flex items-center w-full h-12 px-3 mt-2 rounded-lg  cursor-pointer  hover:all-btn-color ${location.pathname === "/app/AboutFirstScreen" ? "text-white	 bg-[#4E2C7A]  rounded-lg" : "text-[#898CAF]"}`}>
//                 <MdOutlineInfo className="w-7 h-7" />
//                 <span className="ml-4 text-lg font-medium">About First Screen</span>
//               </div>

//               <div onClick={() => onClickMenu('/app/OrkidService')} className={`flex items-center w-full h-12 px-3 mt-2 rounded-lg  cursor-pointer  hover:all-btn-color ${location.pathname === "/app/OrkidService" ? "text-white	 bg-[#4E2C7A]  rounded-lg" : "text-[#898CAF]"}`}>
//                 <TbMessages className="w-7 h-7" />
//                 <span className="ml-4 text-lg font-medium">Orkids Service</span>
//               </div>

//               <div onClick={() => onClickMenu('/app/Acknowledgements')} className={`flex items-center w-full h-12 px-3 mt-2 rounded-lg  cursor-pointer  hover:all-btn-color ${location.pathname === "/app/Acknowledgements" ? "text-white	 bg-[#4E2C7A]  rounded-lg" : "text-[#898CAF]"}`}>
//                 <FaAward className="w-7 h-7" />
//                 <span className="ml-4 text-lg font-medium">Acknowledgements</span>
//               </div>
//               <div onClick={() => onClickMenu('/app/support')} className={`flex items-center w-full h-12 px-3 mt-2 rounded-lg  cursor-pointer  hover:all-btn-color ${location.pathname === "/app/support" ? "text-white	 bg-[#4E2C7A]  rounded-lg" : "text-[#898CAF]"}`}>
//                 <FaMailBulk className="w-7 h-7" />
//                 <span className="ml-4 text-lg font-medium">Contact Us</span>
//               </div>

//               <div onClick={logoutSubmit} className={`flex items-center w-full h-12 px-3 mt-2 rounded-lg  cursor-pointer  hover:all-btn-color ${location.pathname === "/app/login" ? "text-white	 bg-[#4E2C7A]  rounded-lg" : "text-[#898CAF]"}`}>
//                 <FaPowerOff className="w-7 h-7" />
//                 <span className="ml-4 text-lg font-medium">Logout</span>
//               </div>

//               <div className="">



//                 <div onClick={() => onClickMenu('/app/donation')} className="  cursor-pointer  rounded-xl w-full flex justify-center items-center text-lg font-medium">
//                   <img className=" w-40 " src={Donate} alt="Donate" />
//                 </div>

//               </div>
//             </nav>
//           </div>
//         </div>
//       </div>
//     </>
//   );

// }

// function mapStateToProps(state) {
//   const { users } = state;
//   const { overview } = users ? users : {};
//   const { setting, user } = overview ? overview : {};
//   return {
//     users,
//     setting,
//     user
//   };
// }


// export default (connect(mapStateToProps)(withRouter(Sidebar)));










import React from "react";
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { MdOutlineInfo } from "react-icons/md";
import { FaUserTie, FaAward, FaMailBulk, FaPowerOff } from "react-icons/fa";
import { TbMessages } from "react-icons/tb";
import Donate from "./Donate.png";

function Sidebar({ location, history }) {
  const [navbarOpen, setNavbarOpen] = React.useState(false);

  const onClickMenu = (url) => {
    history.push(url);
    setTimeout(() => setNavbarOpen(false), 150);
  };

  const logout = (url) => {
    window.sessionStorage.removeItem('user');
    history.push(url);
    setTimeout(() => setNavbarOpen(false), 150);
  };

  const logoutSubmit = () => {
    confirmAlert({
      title: 'Are you sure you want to logout?',
      buttons: [
        {
          label: 'Logout',
          onClick: () => logout('/login'),
        },
        {
          label: 'Cancel',
        },
      ],
      overlayClassName: 'custom-overlay',
    });
  };

  const renderNavItem = (icon, label, path) => (
    <div
      onClick={() => onClickMenu(path)}
      className={`flex items-center w-full h-12 px-3 mt-2 rounded-lg cursor-pointer hover:all-btn-color transition-all duration-300 ${location.pathname === path ? 'text-white bg-[#4E2C7A] rounded-lg' : 'text-[#898CAF]'
        }`}
    >
      {icon}
      <span className="ml-4 text-lg font-medium">{label}</span>
    </div>
  );

  return (
    <>
      <button
        className={`${navbarOpen ? 'hidden' : 'flex'
          } absolute top-0 left-0 px-5 items-center justify-center text-[#4D207A] focus:outline-none focus:bg-gray-800 focus:text-gray-600 z-50 h-16 lg:hidden`}
        onClick={() => setNavbarOpen(true)}
      >
        <svg className="w-6 h-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h7" />
        </svg>
      </button>

      {navbarOpen && (
        <div className="lg:hidden lg:flex-shrink-0 lg:static inset-0 z-50 fixed bg-opacity-40" onClick={() => setNavbarOpen(false)}>
          <div className="flex items-center justify-center h-8 w-8 rounded-full focus:outline-none focus:bg-gray-600 bg-[#1C2745] absolute left-[260px] lg:left-80 top-2 z-50">
            <svg className="w-6 h-6 text-white z-50" stroke="currentColor" fill="none" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </div>
        </div>
      )}

      <div className={`fixed left-0 inset-y-0 lg:static lg:left-auto lg:inset-auto lg:flex lg:w-80 w-64 z-50 lg:m-10 m-4 ${navbarOpen ? 'flex' : 'hidden'}`}>
        <div className="relative flex flex-col w-64 overflow-y-auto lg:w-80 bg-right-top bg-no-repeat">
          <div className="flex flex-col flex-1 pt-1 bg-[#F6F0FF] rounded-[14px]">
            <div onClick={() => onClickMenu('/app/dashboard')} className="sticky top-0 z-50 flex items-center w-full py-2 px-4 border-b-2 border-[#A9A9AA]/20 cursor-pointer">
              <img className="w-full" src="/img/Frame.png" alt="gcn.png" />
            </div>
            <nav className="flex-1 w-full px-5 py-4 space-y-4">
              {renderNavItem(<FaUserTie className="w-7 h-7" />, 'Dashboard', '/app/dashboard')}
              {renderNavItem(<MdOutlineInfo className="w-7 h-7" />, 'About First Screen', '/app/AboutFirstScreen')}
              {renderNavItem(<TbMessages className="w-7 h-7" />, 'Orkids Service', '/app/OrkidService')}
              {renderNavItem(<FaAward className="w-7 h-7" />, 'Acknowledgements', '/app/Acknowledgements')}
              {renderNavItem(<FaMailBulk className="w-7 h-7" />, 'Contact Us', '/app/support')}
              <div onClick={logoutSubmit} className={`flex items-center w-full h-12 px-3 mt-2 rounded-lg cursor-pointer hover:all-btn-color ${location.pathname === "/app/login" ? "text-white bg-[#4E2C7A] rounded-lg" : "text-[#898CAF]"}`}>
                <FaPowerOff className="w-7 h-7" />
                <span className="ml-4 text-lg font-medium">Logout</span>
              </div>
              <div onClick={() => onClickMenu('/app/donation')} className="cursor-pointer rounded-xl w-full flex justify-center items-center text-lg font-medium">
                <img className="w-40" src={Donate} alt="Donate" />
              </div>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  const { users } = state;
  const { overview } = users || {};
  const { setting, user } = overview || {};
  return { users, setting, user };
};

export default connect(mapStateToProps)(withRouter(Sidebar));
