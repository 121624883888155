import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { MdOutlineChevronLeft } from "react-icons/md";
import Appointment from './Appointment';

class Service extends Component {
  state = {
    data: null,
    loading: true,
    error: null,
    isOpen:false,
  };

  componentDidMount() {
    this.fetchData();
  }

  onClose=()=>{
    this.setState({ isOpen: false });
  }


  fetchData = async () => {
    const url = 'https://orkid.jamsara.com';
    const token = `Bearer ${(JSON.parse(window.sessionStorage.getItem("user"))).token}`;

    try {
      const response = await axios.post(
        `${url}/api/v1/getServiceById`,
        { serviceId: this.props?.match?.params?.id },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token
          }
        }
      );

      if (response.data && !response.data.error) {
        console.log("data", response.data)
        this.setState({ data: response.data.data, loading: false });
      } else {
        this.setState({ error: response.data.message, loading: false });
        console.error('Error fetching data:', response.data.message);
      }
    } catch (error) {
      this.setState({ error: error.message, loading: false });
      console.error('API call error:', error);
    }
  };

  onClickMenu = (url) => {
    this.props.history.push(url);
  };

  handleOpen = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen
    }));
  };
  

  render() {
    const { data, loading, error } = this.state;
    console.log("dddddddddddddd", data)
    // console.log("pppppppppppppppppppppp", this.props)



    return (
      <>
        <div className="h-screen xl:py-10 py-6 overflow-y-auto overflow-hidden">
          <div className='lg:w-7/12 w-11/12 mx-auto'>
            <div className='bg-[#F6F0FF] px-2 py-4 space-y-6'>
              <div className="flex items-center justify-between p-6 py-2">
                <div className="rounded-full p-1 bg-[#fff] shadow-lg" onClick={() => this.props.history.goBack()}>
                  <MdOutlineChevronLeft className='text-[#4E2C7A]' size={30} />
                </div>
                <div className="">
                  {data && data.service && data.service.en ? (
                    <h1>{data.service.en}</h1>
                  ) : (
                    !loading && <p>No content available.</p>
                  )}
                  {/* <h1>{data.service.en}</h1> */}
                </div>
                <div>
                </div>
              </div>
            </div>

            <div className='text-center space-y-6 py-10'>
              {loading && <p>Loading...</p>}
              {error && <p>Error: {error}</p>}
              {data && data.content && data.content.en ? (
                <div className=''>
                  <div className='flex items-center flex-col justify-center text-center space-y-6' dangerouslySetInnerHTML={{ __html: data.content.en }}>
                  </div>
                  <div onClick={()=>this.handleOpen()} className=' bg-[#4e2c7a] text-white py-3 text-xl rounded-md my-3 cursor-pointer  shadow-lg'>Book Appointment</div>
                  {/* <div className='flex justify-center items-center'>

                  </div> */}
                </div>
              ) : (
                !loading && <p>No content available.</p>
              )}
            </div>
          </div>
        </div>
        <Appointment 
        onClose={this.onClose}
        isOpen={this.state.isOpen}
        />
        </>
    );
  }
}

function mapStateToProps(state) {
  const { users } = state;
  const { overview } = users ? users : {};
  return {
    users,
    user: overview ? overview.user : null,
  };
}

export default connect(mapStateToProps)(Service);
